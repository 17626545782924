@import "../../styles/tools";
@import "../../styles/mediaqueries";

.ctaModal {
  margin: 0;

  &.onlyTop {
    margin-top: pxToRem(40);
  }

  &.onlyBottom {
    margin-bottom: pxToRem(40);
  }

  &.both {
    margin-top: pxToRem(40);
    margin-bottom: pxToRem(40);
  }
}

.modal {
  overflow-y: scroll;
  max-width: pxToRem(1152);
  height: 80vh;

  .close {
    all: unset;
    cursor: pointer;
    position: absolute;
    right: var(--space-3);
    top: var(--space-3);

    @include laptop {
      right: var(--space-6);
      top: var(--space-6);
    }
  }
}
