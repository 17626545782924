@import "../../styles/tools";
@import "../../styles/mediaqueries";

.paginationContainer {
  display: flex;
  justify-content: center;
  gap: pxToRem(6);

  li {
    display: flex;
    align-items: center;
    justify-content: center;

    * {
      line-height: 1;
    }
  }

  .button {
    background-color: var(--white);
    color: var(--black);
    border: 0;
    border-radius: 50%;
    padding: 0;
    width: var(--space-6);
    height: var(--space-6);
    line-height: 1;
    font-weight: var(--font-weight-bold);
    font-size: pxToRem(14);
    transition: all 0.25s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: inset 0 0 0 2px var(--tobii-link-color);

    &:hover {
      background-color: var(--tobii-link-color);
      color: var(--white);
    }

    &.active {
      box-shadow: inset 0 0 0 2px var(--tobii-link-color-hover);
      background-color: var(--tobii-link-color-hover);
      color: var(--white);
    }
  }
}
