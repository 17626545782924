@import "../../styles/tools";
@import "../../styles/mediaqueries";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  margin-block: var(--space-6);

  .tagsWrapper,
  .shareWrapper {
    display: flex;
  }

  .tagsWrapper {
    flex-wrap: wrap;
    gap: var(--space-1);
    justify-content: center;
  }

  .shareWrapper {
    display: flex;
    flex-direction: column;

    &::before {
      content: attr(aria-label);
      width: 100%;
      text-transform: uppercase;
      font-size: pxToRem(10);
      font-weight: var(--font-weight-medium);
      color: var(--foreground-1);

      @include laptop {
        width: auto;
      }
    }

    .qrCode {
      width: pxToRem(120);
      height: pxToRem(120);
      background-color: var(--white);
    }

    .list {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: var(--space-1);
    }
  }
}
