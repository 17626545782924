@import "../../../styles/tools";
@import "../../../styles/mediaqueries";

.richText {
  // Headings
  .heading {
    &.heading-1 {
    }
    &.heading-2 {
    }
    &.heading-3 {
    }
    &.heading-4 {
    }
    &.heading-5 {
    }
    &.heading-6 {
    }
  }

  // Text elements
  .paragraph {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-child {
    }
  }

  // Lists
  .bulletList,
  .orderedList {
    padding: 0;
    list-style: none;
    counter-reset: item;
  }

  .listItem {
    position: relative;
    margin-top: pxToRem(12);

    &:last-child {
      margin-bottom: 0;
    }

    p {
      margin: 0;
    }
  }

  .bulletList {
    .listItem {
      padding-left: var(--space-4);
      &::before {
        content: "";
        position: absolute;
        height: pxToRem(4);
        width: pxToRem(4);
        border-radius: 45%;
        background-color: var(--foreground-2);
        left: var(--space-2);
        top: pxToRem(12);
      }
    }
  }

  .orderedList {
    counter-reset: item 0;

    @for $i from 2 through 20 {
      &[start="#{$i}"] {
        counter-reset: item #{$i - 1};
      }
    }

    .listItem {
      padding-left: var(--space-3);
      &::before {
        content: counter(item) ".";
        counter-increment: item;
        position: absolute;
        left: 0;
        font-variant-numeric: tabular-nums;
        color: var(--foreground-2);
      }

      p {
        position: relative;
        top: -2px;
      }
    }
  }

  // Links
  a:not([class*="Button"]) {
    color: var(--tobii-link-color);
    word-break: break-all;
  }

  // Images
  .imageWrapper {
  }

  .imageContainer {
  }

  .image {
  }

  .caption {
  }

  // Blockquotes
  .blockquote {
  }

  // Horizontal rule
  .hr {
  }

  &:empty {
    display: none;
    height: 0;
  }

  // Left alignment
  &.alignLeft {
    text-align: left;

    .bulletList,
    .orderedList {
      margin-left: 0;
      margin-right: auto;
    }

    .orderedList {
      margin-left: var(--space-2);
    }
  }

  // Center alignment
  &.alignCenter {
    text-align: center;

    .bulletList,
    .orderedList {
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
    }
  }

  // Right alignment
  &.alignRight {
    text-align: right;

    .bulletList,
    .orderedList {
      margin-left: auto;
      margin-right: 0;
      width: fit-content;
      direction: rtl;
      padding: 0 var(--space-2) 0 0;

      .listItem {
        direction: ltr;
        text-align: right;

        &::before {
          left: auto;
          right: pxToRem(-16);
        }
      }
    }

    .bulletList {
    }

    .orderedList {
      .listItem {
        padding-right: var(--space-2);

        &::before {
          content: "." counter(item);
        }
      }
    }
  }
}

/////////////////////////////////////
/// Inverted
/////////////////////////////////////
.inverted {
  .paragraph {
    color: var(--white);
  }

  .heading {
    color: var(--white);
  }

  // Lists
  .bulletList,
  .orderedList {
    .listItem {
      color: var(--white);

      &::before {
        background-color: var(--foreground-i2);
      }
    }
  }

  .orderedList {
    .listItem {
      &::before {
        color: var(--foreground-i2);
      }
    }
  }

  a:not([class*="Button"]) {
    color: var(--foreground-i1);
    text-decoration: underline;
    text-decoration-color: var(--foreground-i2);

    &:hover {
      color: var(--foreground-i2);
    }
  }

  .blockquote {
    border-color: var(--foreground-i2);
    color: var(--foreground-i1);
  }

  .hr {
    border-color: var(--foreground-i2);
  }
}
