@import "../../styles/mediaqueries";

.waveDivider {
  position: relative;
  z-index: 1;
  width: 100%;

  &.top {
    margin-top: -17%;

    @include laptop {
      margin-top: -12%;
    }

    // animation: slide 1s cubic-bezier(0.2, 0.6, 0.3, 1) 1s both;
  }

  &.bottom {
    margin-top: 0;
    margin-bottom: -24%;

    @include laptop {
      margin-bottom: -13%;
    }
  }

  // overrides next/image
  > span {
    position: unset !important;

    > span {
      padding: 0 !important;
    }

    img {
      object-fit: contain;
      width: 100% !important;
      position: relative !important;
      height: unset !important;
    }
  }
}

@keyframes slide {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
