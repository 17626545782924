@import "../../../styles/mediaqueries";
@import "../../../styles/tools";
@import "../../../styles/mixins";

:export {
  iconcolor: #{var(--white)};
}

.investorGridInner {
  display: grid;
  gap: var(--space-3);
  grid-template-columns: 1fr;
  grid-template-areas:
    "ticker"
    "events"
    "press";

  @include laptop {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "ticker ticker"
      "events press";
  }

  @include desktop {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "ticker events press";
  }

  .shareTicker {
    grid-area: ticker;
  }

  .financialEvents {
    grid-area: events;
  }

  .pressReleases {
    grid-area: press;
  }
}

// Share ticker
.shareTicker {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--space-4);
  background-color: var(--signature);
  color: var(--white);
  text-align: center;

  p {
    margin: 0;
    font-weight: var(--font-weight-bold);
    font-size: pxToRem(16);
  }

  .latestUpdate {
    font-weight: var(--font-weight-normal);
    font-size: pxToRem(12);
    color: var(--foreground-i2);
  }

  .icon {
    margin-bottom: var(--space-4);
    width: var(--space-6);
  }

  .data {
    padding: 1rem;
    color: var(--white);

    .mainData {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .price {
        font-size: 1.5rem;
        font-weight: bold;

        .currency {
          font-size: 0.875rem;
          margin-left: 0.25rem;
          opacity: 0.7;
        }
      }

      .change {
        display: flex;
        align-items: center;
        font-weight: 500;

        &.positive {
          color: #22c55e;
        }

        &.negative {
          color: #ef4444;
        }

        .trendIcon {
          margin-right: 0.25rem;
        }
      }
    }

    .additionalData {
      .dataRow {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        &:first-child {
          padding-top: 0;
          border-top: none;
        }

        .label {
          color: var(--white);
        }

        .value {
          font-weight: 500;

          &.positive {
            color: #22c55e;
          }

          &.negative {
            color: #ef4444;
          }
        }
      }
    }
  }

  .error {
    color: #ef4444;
    text-align: center;
    padding: 1rem;
  }

  .loading {
    text-align: center;
    padding: 1rem;
    color: rgba(0, 0, 0, 0.5);
  }
}

.listHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--space-2);
  border-bottom: var(--border);
}

.list {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  padding: var(--space-2) 0;
}

.listItem,
.listEventItem {
  p {
    margin: 0;
  }

  .title {
    color: var(--foreground-1);
    text-decoration: none;
    transition: 0.3s ease-in-out;
    font-weight: var(--font-weight-medium);
    font-size: pxToRem(16);
  }
}

.listItem {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);

  + .listItem {
    padding-top: var(--space-2);
    border-top: 1px solid var(--stroke-1);
  }

  time {
    font-size: pxToRem(12);
    font-weight: var(--font-weight-medium);
    color: var(--foreground-1);
    text-transform: uppercase;
  }

  .title {
    margin-top: 0;

    &:hover {
      color: var(--tobii-link-color);
    }
  }
}

.listEventItem {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);

  @include tablet {
    flex-direction: row;
    align-items: flex-start;
  }

  + .listEventItem {
    padding-top: var(--space-2);
    border-top: 1px solid var(--stroke-1);
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
  }

  .date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: pxToRem(20) 0;
    background-color: var(--tobii-ocean-blue-90b);
    color: var(--white);

    @include tablet {
      min-width: var(--space-12);
    }

    .day {
      font-size: pxToRem(20);
      font-weight: var(--font-weight-bold);

      @include tablet {
        font-size: pxToRem(32);
      }
    }
  }

  .details {
    display: flex;
    align-items: center;
    gap: var(--space-2);
  }

  .tag {
    @include tag;
  }

  .location {
    color: var(--foreground-1);
    margin-top: 0;
    line-height: pxToRem(22);
  }
}

.calendarLinks {
  display: flex;
  align-items: center;
  gap: var(--space-1);

  a {
    @include tag;

    &:hover {
      background-color: var(--tobii-link-color);
      color: var(--white);
    }
  }
}
