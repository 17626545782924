@import "../../../styles/mediaqueries";
@import "../../../styles/tools";
@import "../../../styles/mixins";

.videoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--tobii-ocean-blue);

  .reactPlayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;

    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: opacity 0.3s ease-out;
    }
  }

  &.isCompleted {
    video {
      opacity: 1;
      object-fit: cover;
    }
  }

  .thumbnailWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    > * {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &[role="button"] {
      cursor: pointer;
    }

    &:hover {
      .circle {
        background-color: var(--tobii-link-color-hover);
      }

      .overlay {
        opacity: 0.75;
      }
    }
  }

  .overlay {
    position: absolute;
    background-color: var(--overlay);
    opacity: 0.5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.25s;
  }

  .playButton {
    all: unset;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 16 / 9;
    cursor: pointer;

    .circle {
      position: relative;
      display: flex;
      width: var(--space-10);
      height: var(--space-10);
      border-radius: 50%;
      background-color: var(--tobii-link-color);
      transition: all 0.25s;
      @include soft-float;

      svg {
        position: absolute;
        top: 50%;
        left: 54%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

:export {
  iconcolor: var(--white);
}
