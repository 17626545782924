@import "../../styles/tools";

.contact {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  padding: var(--space-3);
  border: var(--border);
}

.imageWrapper {
  display: flex;
  align-items: center;
  gap: pxToRem(18);

  h3 {
    font-size: pxToRem(12);
  }

  .title {
    margin: 0;
    font-size: pxToRem(12);
  }
}
