@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

:export {
  iconcolor: #{var(--white)};
}

.section {
  .intro {
    margin-bottom: var(--space-3);
  }

  &.searchHits {
    padding: 0 !important;
  }
}

.contentGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--space-6);

  a {
    text-decoration: none;
  }

  @include laptop {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include xl {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    &.twoColumns {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &.noGap {
    column-gap: unset;
  }

  &.marginTop {
    margin-top: var(--space-4);
  }

  &.startpage {
    opacity: 0;
    transition: opacity 1s ease;

    &.inViewport {
      opacity: 1;
    }
  }
}

.item {
  .imageContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 4 / 3;
    overflow: hidden;
    @include radius("lg");

    img {
      transition: 0.3s ease-in-out;
      transition-property: transform, object-position;
    }

    &.alignleft {
      img {
        object-fit: cover;
        object-position: 20% center;
      }
    }
  }

  .link {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    grid-gap: var(--space-2);

    &:hover {
      h3 {
        color: var(--tobii-link-color);
      }

      .imageContainer img {
        transform: scale(1.1);
        transition: 0.3s ease-in-out;
      }
    }
  }
}

.listHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--space-2);
  border-bottom: var(--border);

  a {
    @include tag;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  padding: var(--space-2) 0;
}

.listItem,
.listEventItem {
  a {
    color: var(--foreground-1);
    text-decoration: none;
    transition: 0.3s ease-in-out;
    font-weight: var(--font-weight-medium);

    &:hover {
      color: var(--tobii-link-color);
    }
  }
}

.listItem {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);

  + .listItem {
    padding-top: var(--space-2);
    border-top: 1px solid var(--stroke-1);
  }

  time {
    font-size: pxToRem(12);
    font-weight: var(--font-weight-medium);
    color: var(--foreground-1);
    text-transform: uppercase;
  }
}

.listEventItem {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);

  @include tablet {
    flex-direction: row;
    align-items: flex-start;
  }

  + .listEventItem {
    padding-top: var(--space-2);
    border-top: 1px solid var(--stroke-1);
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: pxToRem(12);

    a {
      font-size: pxToRem(18);
    }
  }

  .date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: pxToRem(20) 0;
    background-color: var(--tobii-ocean-blue-90b);
    color: var(--white);

    @include tablet {
      min-width: var(--space-12);
    }

    .day {
      font-size: pxToRem(20);
      font-weight: var(--font-weight-bold);

      @include tablet {
        font-size: pxToRem(32);
      }
    }
  }

  .details {
    display: flex;
    align-items: center;
    gap: var(--space-2);
  }

  .tag {
    @include tag;
  }

  .location {
    color: var(--foreground-1);
    margin-top: 0;
    line-height: pxToRem(22);
  }
}

.container {
  background-color: var(--foreground-i1);
}

.publicationContentGrid {
  padding-bottom: 0;

  .contentGrid {
    grid-gap: 0;
    padding-bottom: 0;

    @include laptop {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .pubDetailsWrapper {
      background-color: var(--white-transparent-75);
      @include backdrop-blur();
      @include cool-glow(0.5);
      @include radius("md");
      transition: 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
      gap: var(--space-1);
      padding: var(--space-4);
      margin-bottom: var(--space-4);

      &:hover {
        background-color: var(--white);
        @include cool-glow(1);

        h3 {
          color: var(--tobii-link-color);
        }
      }

      a {
        h3 {
          transition: 0.3s ease-in-out;
        }
      }

      .products {
        display: flex;
        flex-direction: row;
        gap: var(--space-1);
        margin: 0;
        padding: 0;
      }

      .meta {
        font-size: pxToRem(12);
        color: var(--foreground-1);

        strong {
          padding: 0 var(--space-1);
        }

        > div {
          margin-top: var(--space-1) / 2;
        }
      }

      .abstract {
        word-break: break-word;
        font-size: pxToRem(16);
        margin: 0;

        .expandButton {
          all: unset;
          display: inline-flex;
          font-size: pxToRem(12);
          font-weight: var(--font-weight-medium);
          margin-left: var(--space-1);
          color: var(--foreground-1);
          cursor: pointer;
        }
      }

      .icon {
        margin-left: pxToRem(4);
        transition: transform 0.2s ease;

        &.open {
          transform: rotate(180deg);
        }
      }
    }

    .tag {
      @include tag();
    }
  }
}
