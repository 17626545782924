/* stylelint-disable */

@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.textImage {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  place-items: stretch;

  @include laptop {
    flex-direction: row;

    &.reversed {
      flex-direction: row-reverse;
    }

    .content,
    .visual {
      width: percentage(1/2);
    }
  }

  @include desktop {
    min-height: pxToRem(500);

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &.reversed {
      .content {
        align-items: flex-start;
      }
    }
  }

  @include huge {
    justify-content: center;
  }

  &.none {
    .content {
      @include laptop-down {
        border-radius: 0;
        padding: 0 0 var(--space-6) 0;
      }
    }
  }
}

// Layout
.layout11,
.layout12,
.layout21 {
  gap: var(--space-4);

  @include xl {
    gap: var(--space-6);
  }

  .visual,
  .content {
    @include radius("lg");
  }
}

.layout11 {
}

.layout12 {
  .content {
    @include xl {
      width: percentage(1/3);
    }
  }

  .visual {
    @include xl {
      width: percentage(2/3);
    }
  }
}

.layout21 {
  .content {
    @include xl {
      width: percentage(2/3);
    }
  }

  .visual {
    @include xl {
      width: percentage(1/3);
    }
  }
}

// Visual
.visual {
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include radius("lg");
  }

  // Media background
  .mediaBgWhite {
    //background-color: var(--white);
  }
  .mediaBgGray {
    //background-color: var(--background-2);
  }
  .mediaBgSignature {
    background-color: var(--signature);
  }
  .mediaBgBlack {
    background-color: var(--black);
  }
  .mediaBgLitterbox {
    @include litterbox;
    outline: none !important;
    box-shadow: none !important;
  }
}

.smallSection {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  place-items: stretch;
  height: 100%;

  @include laptop {
    flex-direction: row;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &.reversed {
      flex-direction: row-reverse;

      .content {
        align-items: flex-start;
      }
    }
  }

  @include desktop {
    min-height: pxToRem(400);
  }

  .title {
    font-weight: var(--font-weight-bold);
  }

  p:first-of-type {
    margin-top: 0;
  }

  .details {
    line-height: 18px;
    display: flex;
    align-self: flex-end;
    flex-flow: row wrap;
    list-style-type: none;
    font-size: pxToRem(12);
    width: 100%;

    .detailsItem {
      font-size: pxToRem(12);
      font-weight: unset;
    }

    p {
      margin-top: 0;
    }

    p + p {
      &::before {
        content: "|";
        margin: 0 0.5em;
      }
    }
  }

  @include huge {
    justify-content: center;
  }

  .smallContent {
    text-align: left;
    justify-content: center;
    padding-left: 0;

    @include laptop {
      width: 50%;
    }
  }
}
.imageContainer {
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  aspect-ratio: 16 / 9;

  img {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }

  @include huge {
    max-width: pxToRem(1280);
  }

  @include desktop {
    img {
      position: absolute;
    }

    width: 50%;
  }

  &.imageKeepProportions {
    img {
      object-fit: contain !important;
    }
  }
}

.imageContainerSmall {
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  @include radius("lg");

  @include laptop {
    display: grid;
    width: 50%;
  }

  @include huge {
    max-width: pxToRem(1280);
  }

  img {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }

  aspect-ratio: 16 / 9;

  @include desktop {
    img {
      position: absolute;
    }

    width: 50%;
  }
}

.videoContainer {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Content
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--space-2);
  padding: var(--space-6) var(--space-4);
  margin: 0;
  position: relative;
  overflow: hidden;
  @include radius("lg");

  @include desktop {
    padding: var(--space-10) var(--space-6);
  }

  .title,
  .text,
  .buttons {
    width: 100%;
  }

  .title {
    font-weight: var(--font-weight-medium);
  }

  .text {
    p:first-child {
      margin-top: 0;
    }
  }

  .buttons {
    display: flex;
    gap: var(--space-2);
    flex-direction: row;
    place-items: flex-start;
    justify-content: left;
  }
}

.smallContent {
  padding: var(--space-2);

  @include desktop {
    padding: pxToRem(40) var(--space-3);
  }
}
