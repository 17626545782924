/* eslint-disable */
@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

:export {
  iconColor: var(--white);
  iconColorInverted: var(--black);
}

.filterListContainer {
  display: flex;
  position: relative;
  width: 100%;

  &.dropdown {
    .filterList {
      flex-direction: column;
      max-height: pxToRem(360);
      scrollbar-width: thin;
      scrollbar-color: var(--border-color) transparent;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar {
        height: pxToRem(4);
        border-radius: pxToRem(5);
        background-color: var(--stroke-i1);
      }

      &::-webkit-scrollbar-thumb {
        height: pxToRem(4);
        border-radius: pxToRem(5);
        background-color: var(--foreground-i1);
      }
    }
  }

  &.expand {
    justify-content: center;

    .filterList {
      padding: 0;
      flex-direction: row;
      flex-wrap: wrap;
      gap: var(--space-1);
    }

    .filterContainer {
      box-shadow: inset 0 0 0 2px var(--tobii-link-color);
    }
  }
}

.filterToggle {
  all: unset;
  padding: 0 var(--space-2);
  @include radius("lg");
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--tobii-link-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-1);
  width: 100%;
  transition: all 0.25s;
  height: pxToRem(40);
  color: var(--black);

  &:hover {
    background-color: var(--tobii-frost-blue);

    .selectedCount {
      background-color: var(--tobii-frost-blue-30b);
    }
  }

  &:focus-visible {
    outline: 2px solid var(--tobii-link-color-hover);
    outline-offset: 2px;
  }

  &.isOpen {
    background-color: var(--tobii-link-color);
    color: var(--white);

    .selectedCount {
      background-color: var(--tobii-frost-blue);
    }
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: var(--space-1);
  }

  .label {
    font-size: pxToRem(14);
    font-weight: var(--font-weight-medium);
  }

  .selectedCount {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: pxToRem(24);
    height: pxToRem(24);
    background-color: var(--tobii-frost-blue);
    color: var(--black);
    @include radius("xl");
    font-size: pxToRem(10);
    font-weight: var(--font-weight-bold);
    line-height: 1;
    transition: all 0.25s;
  }

  .arrow {
  }
}

.filterListWrapper {
  position: absolute;
  top: calc(100% + 4px);
  background-color: var(--white);
  @include radius("md");
  @include soft-float;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.2s ease;
  width: pxToRem(280);
  overflow: hidden;
  pointer-events: none;
  transform: none;

  &.isOpen {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateY(0);
  }

  .noFilterOptions {
    padding: var(--space-2);
    font-size: pxToRem(12);
  }
}

.filterListSearch {
  padding: var(--space-2);
  border: none;
  border-bottom: 1px solid var(--tobii-light-gray);
}

.filterList {
  list-style: none;
  display: flex;
  padding: var(--space-2);
  gap: pxToRem(2);
  position: relative;
}

.heading {
  display: flex;
  align-items: flex-start;
  gap: var(--space-1);
  padding: 0 0.25rem;
  cursor: pointer;
  margin: 0;
  font-size: pxToRem(14);
  font-weight: var(--font-weight-medium);

  &:focus-visible {
    outline: none;
  }
}

.children {
  --duration: 300ms;

  border: 0;
  margin: 0;
  padding: 0;
  max-height: 0;
  opacity: 0;
  transition: opacity var(--duration) ease, max-height var(--duration) ease;
}

.filterList[open] .children {
  max-height: 33vh;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.filter {
  margin: 0;

  &:last-child {
    margin-bottom: 0;
  }

  .filterLabel {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    cursor: pointer;
    text-transform: none;
    font-size: pxToRem(12);

    .name {
    }
    .count {
      font-size: pxToRem(10);
      line-height: 1;
      font-weight: var(--font-weight-bold);
      user-select: none;
    }
  }

  .filterContainer {
    display: flex;
    width: 100%;
    border: 0;
    padding: var(--space-1) calc(var(--space-1) * 1.5);
    gap: var(--space-1);
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    justify-content: space-between;
    @include radius("md");
    transition: all 0.25s;

    &:hover {
      background-color: var(--tobii-frost-blue);
    }
  }

  .filterInput:checked + .filterContainer {
    background-color: var(--tobii-link-color);
    font-weight: var(--font-weight-bold);
    color: var(--white);

    &:hover {
      background-color: var(--tobii-link-color);
    }
  }

  .filterInput:focus-visible + .filterContainer {
    background-color: var(--tobii-link-color);
    color: var(--white);
  }
}

.filterInput {
  position: absolute;
  opacity: 0;

  &:focus-visible + .filterContainer {
    outline: 2px solid var(--color-primary);
    outline-offset: -2px;
    @include radius("md");
  }

  &:checked + .filterContainer {
    background-color: var(--background-selected);
    font-weight: var(--font-weight-medium);
  }
}

.clearButton {
  display: flex;
  justify-content: space-between;
}
