@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.downloads {
  padding: pxToRem(40) 0;
  background-color: var(--background-3);

  @include laptop-large {
    padding: var(--space-12) 0;
    text-align: center;
  }

  a {
    color: inherit;
  }
}

.list {
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  gap: var(--space-3);
  margin-top: var(--space-6) !important;
  flex-grow: 0;
  flex-shrink: 0;

  @include tablet {
    gap: var(--space-4);
    flex-direction: row;
    justify-content: center;
  }
}

.item {
  display: flex;

  @include tablet {
    flex-basis: calc(49% - calc(var(--space-2) * 4 / 5));
  }

  @include laptop {
    flex-basis: calc(32% - calc(var(--space-2) * 4 / 5));
  }

  @include desktop {
    flex-basis: calc(23% - calc(var(--space-2) * 4 / 5));
  }

  @include xxl {
    flex-basis: calc(15% - calc(var(--space-2) * 4 / 5));
  }

  button {
    all: unset;
    cursor: pointer;
  }

  a,
  button {
    @include radius("md");
    display: flex;
    gap: pxToRem(18);
    width: 100%;
    padding: var(--space-3) pxToRem(26);
    text-decoration: none;
    background-color: var(--white);
    transition: all 0.25s;
    @include cool-glow(0.5);

    &:hover {
      color: var(--white);
      background-color: var(--tobii-link-color);
      @include cool-glow(1);
      transform: translateY(-4px);

      .title,
      .subtitle {
        color: var(--white);
      }

      img {
        filter: invert(1);
      }
    }

    @include tablet {
      align-items: center;
      gap: var(--space-2);
      flex-direction: column;
      padding: var(--space-3);
    }
  }

  .title,
  .subtitle {
    margin: 0;
    font-size: pxToRem(12);
    transition: all 0.25s;

    @include tablet {
      text-align: center;
    }
  }

  .title {
    color: var(--foreground-1);
    font-weight: var(--font-weight-bold);

    @include laptop-large {
      margin-bottom: pxToRem(4);
    }
  }

  .subtitle {
    color: var(--foreground-2);
  }

  img {
    transition: all 0.25s;
  }
}

.expandableList {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-3);

  @include laptop-large {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: pxToRem(40);
    margin-top: pxToRem(40);
  }

  @include xl {
    grid-template-columns: repeat(3, 1fr);
  }
}

.expandableItem {
  overflow: hidden;
  border-bottom: var(--border);
  padding: 0 var(--space-2);

  @include laptop-large {
    padding: var(--space-4) var(--space-4) var(--space-2) var(--space-4);
    background-color: var(--background-1);
    border: var(--border);
  }

  button {
    all: unset;
    display: flex;
    align-items: center;
    gap: var(--space-2);
    width: 100%;
    padding: var(--space-2) 0;
    cursor: pointer;
  }

  .imageWrapper {
    margin: 0 auto;
    position: relative;
    width: var(--space-4);
    height: var(--space-4);
    border-radius: 50%;
    overflow: hidden;
    border: var(--border);

    @include laptop-large {
      width: var(--space-12);
      height: var(--space-12);
    }
  }

  .buttonIcon {
    transition: transform 0.2s ease;

    &.expanded {
      transform: rotate(180deg);
    }
  }

  h3 {
    flex: 1;
    margin: 0;
    font-size: pxToRem(18);

    @include laptop-large {
      margin-top: var(--space-2);
    }
  }

  .text {
    margin: 0;
    font-size: pxToRem(12);

    @include laptop-large {
      font-size: pxToRem(18);
      margin-top: var(--space-2);
    }
  }

  h4 {
    margin: var(--space-3) 0 0 0;
    font-size: pxToRem(10);
    font-weight: var(--font-weight-medium);
    text-align: left;
    text-transform: uppercase;
    color: var(--foreground-1);

    @include laptop-large {
      margin-top: var(--space-4);
    }
  }

  p {
    font-size: var(--space-2);
  }

  .content {
    max-height: 0;
    opacity: 0;
    transition: all 0.2s ease;
    overflow: hidden;
  }

  .item {
    background-color: transparent;
    border-width: 0 0 1px;

    &:last-of-type {
      border: none;
    }

    a,
    button {
      padding: var(--space-2) 0;

      @include laptop-large {
        flex-direction: row;
        justify-content: flex-start;
      }
    }

    .title {
      margin-bottom: 0;
    }

    .title,
    .subtitle {
      text-align: left;
    }

    .itemWrapper {
      flex: 1;
    }
  }

  &.expandedItem {
    background-color: var(--white);
  }
}

.modal {
  background-color: var(--white) !important;
  padding: var(--space-3) var(--space-2) !important;

  @include laptop {
    padding: var(--space-3) !important;
  }

  .formTitle,
  .formText {
    text-align: center;
  }

  .formTitle {
    color: var(--foreground-1);
    padding: var(--space-2) var(--space-4) 0 var(--space-4);
    margin: 0;
  }

  .formText {
    color: var(--foreground-1);
    font-size: pxToRem(16);

    p:first-child {
      margin-top: 0;
    }
  }

  .formTitle + .formText {
    margin-top: var(--space-2);
  }

  .form {
    border-top: 1px solid var(--stroke-1);
    padding-top: var(--space-4);
    margin-top: var(--space-4);
  }

  .close {
    all: unset;
    cursor: pointer;
    position: absolute;
    right: var(--space-2);
    top: var(--space-2);
    width: var(--space-4);
    height: var(--space-4);

    svg {
      width: var(--space-4);
    }
  }
}
