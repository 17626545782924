@import "../../../styles/mediaqueries";
@import "../../../styles/tools";
@import "../../../styles/mixins";

.calendar {
  margin-left: 0;

  :global {
    .mf-calendar-v2 {
      width: 100%;
    }

    .mf-table {
      width: 100%;
      border-collapse: collapse;
      align-self: start;
      min-width: pxToRem(720);

      th,
      td {
        padding: var(--space-2);
        font-size: pxToRem(14);
      }

      thead {
        td {
          font-size: pxToRem(16);
          border-bottom: 2px solid var(--black);
        }
      }
    }

    .event {
      td {
        transition: all 0.25s;
        border-bottom: 1px solid rgba(14, 16, 62, 0.2);

        &:first-child {
          font-weight: var(--font-weight-bold);
          width: pxToRem(160);
        }

        &:nth-child(4),
        &:last-child {
          text-align: right;
          width: pxToRem(100);
        }

        &.google,
        &.ical {
          width: pxToRem(100);
        }

        a {
          text-decoration: none;
          color: var(--tobii-link-color);
          font-weight: var(--font-weight-medium);
          @include tag;

          &:hover {
            background-color: var(--tobii-link-color);
            color: var(--white);
          }
        }
      }

      &:hover {
        td {
          background-color: rgba(14, 16, 62, 0.05);
        }
      }
    }

    .mf-calendar-header {
      font-weight: var(--font-weight-bold);
      color: var(--signature);

      & + div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: var(--space-1);
      }
    }

    .upcoming-btn,
    .archive-btn,
    .previous-btn,
    .next-btn {
      --fs: #{pxToRem(10)};
      --color: var(--white);
      --shadow-expansion: 0 0 0 0.625rem;
      --shadow-expansion-cta: 0 0 0 1.25rem;
      --disabled-color: rgb(56 61 86 / 40%);
      --disabled-bg: rgb(0 0 0 / 10%);
      --disabled-inverted-color: rgb(255 255 255 / 30%);
      --disabled-inverted-bg: rgb(255 255 255 / 25%);

      all: unset;
      width: fit-content;
      gap: var(--space-2);
      display: flex;
      align-items: center;
      justify-self: baseline;
      color: var(--color);
      font-family: var(--font-heading-medium);
      font-size: var(--fs);
      font-weight: var(--font-weight-normal);
      text-transform: uppercase;
      position: relative;
      appearance: none;
      background: transparent;
      padding: 0.65em 1.3em;
      border: 0;
      border-radius: 50px;
      cursor: pointer;
      will-change: box-shadow;
      transition: all 0.25s;
      background-color: var(--tobii-link-color);

      &:focus::before {
        box-shadow: var(--tobii-link-color) 0 0 0 2px;
      }

      background-image: radial-gradient(#0680cd 0%, rgb(0 91 148 / 0%) 80%);
      background-position: 50% -30px;
      background-size: 130% 130%;
      background-repeat: no-repeat;
      box-shadow: none;

      &:hover {
        transition: all 0.25s;
        border-radius: 50px;
        background-position: 50% -35px;
      }

      &:active {
        box-shadow: none;
        transition: all 0s;
        border-radius: 50px;
        background-position: 50% -15px;
        transform: translateY(1px);
      }

      &::after {
        color: var(--tobii-link-color);
      }

      &:focus:not(:active)::after {
        --shadow-expansion: var(--shadow-expansion-cta);

        animation: ray 2s;
        animation-timing-function: cubic-bezier(0, 0.5, 0, 1);
      }
    }

    .upcoming-btn,
    .archive-btn {
      &:not(:disabled) {
        opacity: 0.5;
      }
    }

    .mf-calendar-v2-footer {
      display: flex;
      justify-content: flex-end;
      margin: var(--space-2);
      gap: var(--space-1);

      button {
        &:disabled {
          display: none;
        }
      }
    }
  }
}
