@import "../../styles/tools";
@import "../../styles/mediaqueries";

:export {
  iconcolor: var(--white);
}

.footer {
  scroll-snap-align: start;
  background-color: var(--signature);
  padding: var(--space-7) 0;
  font-size: pxToRem(14);
  color: var(--foreground-i2);
  font-family: var(--font-body);

  &.main {
    background-color: var(--tobii-ocean-blue-90b);
  }

  ul {
    list-style-type: none;
  }

  a:not(.button) {
    transition: all 0.25s;
    color: var(--tobii-frost-blue);
    text-decoration: none;
    font-size: pxToRem(14);

    &:hover {
      text-decoration: underline;
      color: var(--white) !important;
    }
  }

  .button {
    font-size: pxToRem(10);
    grid-gap: var(--space-1);

    &:hover {
      text-decoration: none;
    }
  }

  .icon {
    display: block;
  }
}

.socialList {
  ul {
    display: flex;
    flex-flow: row wrap;

    a {
      svg {
        transition: all 0.25s;
      }

      @include not-touchscreen {
        &:hover {
          text-decoration: none;
          svg {
            --color: var(--white) !important;
          }
        }
      }
    }
  }
}

.footerList {
  display: grid;
  grid-gap: var(--space-2);
  padding: var(--space-3) 0;
  border-top: 2px solid var(--white-transparent-10);

  @include laptop {
    grid-template-columns: 1fr 4fr;
  }

  .title {
    text-transform: uppercase;
    font-size: pxToRem(12);
    font-weight: var(--font-weight-bold);
    color: var(--white);

    @include laptop {
      border-right: 1px solid var(--white-transparent-10);
    }
  }

  ul {
    align-items: center;
    row-gap: var(--space-2);
    column-gap: var(--space-3);
  }

  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(pxToRem(180), 1fr));
  }
}

.footerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  grid-gap: var(--space-2);
  padding: var(--space-3) 0;

  > svg {
    width: pxToRem(64);
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    grid-gap: var(--space-3);
  }
}

.footerFooter {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--space-2);
  padding: var(--space-3) 0;
  border-top: 2px solid var(--white-transparent-10);

  @include laptop {
    grid-template-columns: 1fr 1fr;
  }

  li:last-child {
    @include laptop {
      text-align: right;
    }
  }

  a + a {
    margin-left: var(--space-2);
  }
}

.langPicker {
  all: unset;
  cursor: pointer;
}
