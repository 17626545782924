@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.featuredTabs {
  --title-font-size: #{pxToRem(44)};

  @include laptop-large {
    --title-font-size: #{pxToRem(68)};
  }

  padding-top: pxToRem(80);
  background-color: var(--white);
}

.title {
  font-size: var(--title-font-size);
  font-family: var(--font-heading-medium);
  text-align: center;
  padding-bottom: pxToRem(40);
}

.tabList {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: white;
  border-top: var(--border-no-opacity);
  border-bottom: var(--border-no-opacity);

  ul {
    display: inline-flex;
    overflow-x: scroll;
    background-color: var(--stroke-1);
    padding: 0 pxToRem(1);
    gap: pxToRem(1);

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.expandButton {
  position: relative;
  border-left: var(--border-no-opacity);

  &::before {
    content: "";
    position: absolute;
    left: pxToRem(-41);
    top: 0;
    bottom: 0;
    width: pxToRem(40);
    background: linear-gradient(
      90deg,
      rgb(0 0 0 / 0%) 0%,
      rgb(0 0 0 / 20%) 100%
    );
  }
}

.tab {
  display: flex;

  button {
    all: unset;
    height: pxToRem(45);
    max-width: pxToRem(224);
    width: pxToRem(224);
    padding: 0 pxToRem(10);
    font-size: pxToRem(12);
    font-weight: var(--font-weight-medium);
    color: var(--foreground-1);
    text-transform: uppercase;
    text-align: center;
    background-color: var(--foreground-i1);
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;

    @include laptop {
      height: pxToRem(60);
    }

    &.active {
      background-color: var(--signature);
      color: var(--foreground-i1);
    }

    &.mobileTab {
      max-height: unset;
      height: auto;
      justify-content: flex-start;
      padding: var(--space-2);
      text-align: left;
      width: 100%;
    }
  }
}
