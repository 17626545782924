@import "../../../styles/mediaqueries";
@import "../../../styles/tools";
@import "../../../styles/mixins";

.scrollTriggered {
  margin: 50px 0;
  position: relative;
  top: 0;
}

.stackItem {
  position: sticky;
  top: 0px;
  padding-top: 0px;
  transition: transform 0.2s ease-out;
}
.noGapStackCarousel {
  gap: 0 !important;
  transform: scale(0.95);
  @include radius("lg");
  overflow: hidden;

  > div {
    border-radius: 0 !important;
  }
}

.stackItem:nth-child(2) {
  top: 65px;
}

.stackItem:nth-child(3) {
  top: 130px;
}

.stackItem:nth-child(4) {
  top: 130px;
  transform: translate(0, 400px);
}
