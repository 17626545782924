@import "../../styles/tools";
@import "../../styles/mixins";

.partOfEvent {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  grid-gap: var(--space-2);
  @include radius("md");
  @include cool-glow(0.5);
  position: relative;
  overflow: hidden;

  &:hover {
    .overlay {
      opacity: 0.75;
    }

    .imageContainer img {
      transform: scale(1.1);
      transition: 0.3s ease-in-out;
    }
  }
}

.imageContainer {
  width: 100%;
  aspect-ratio: 4 / 3;
  overflow: hidden;

  img {
    transition: 0.3s ease-in-out;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: var(--space-1);
  padding: var(--space-2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  text-align: center;
  z-index: 2;
  width: 100%;
  height: 100%;

  .label {
    margin-top: 0;
  }

  .title {
    margin-top: 0;
  }

  .details {
    display: flex;
    flex-wrap: wrap;

    li {
      font-size: pxToRem(12);
    }

    li + li {
      &::before {
        content: "•";
        margin: 0 var(--space-1);
      }
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 1;
  opacity: 0.5;
  transition: all 0.25s;
}
