@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.shortFeatures {
  position: relative;

  &.roundedCorners {
    @include radius("lg");
    overflow: hidden;
  }
}

.shortFeaturesInner {
  position: relative;
}

.features {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: var(--space-4);
  margin-top: var(--space-4);

  @include laptop {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include desktop {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &.two {
    @include desktop {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  &.three {
    @include desktop {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  &.four {
    // Same as default
  }
  &.five {
    @include desktop {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }
  &.six {
    @include desktop {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  }
}

.feature {
  height: 100%;
  position: relative;
  overflow: hidden;
  @include radius("md");

  .inner {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    grid-gap: var(--space-2);

    @include tablet {
      flex-direction: row;
      text-align: left;
    }

    @include laptop {
      text-align: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .visual {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include tablet {
      align-items: center;
      justify-content: center;
    }

    @include laptop {
      min-width: pxToRem(112);
    }
  }

  .content {
    display: flex;
    gap: var(--space-1);
    flex-direction: column;

    @include laptop {
      align-items: center;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--space-6);
    height: var(--space-6);
    margin: 0;

    @include laptop {
      width: var(--space-8);
      height: var(--space-8);
    }
  }

  .title {
    color: var(--black);
    font-size: pxToRem(18);
    margin: 0;
  }

  .description {
    font-size: pxToRem(16);
    margin-top: -#{pxToRem(4)};

    p:first-child {
      margin-top: 0;
    }

    &:empty {
      display: none;
    }
  }

  .labelWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: center;
    align-items: center;
    line-height: 1;
    font-weight: var(--font-weight-bold);
    gap: var(--space-1);
    color: var(--black);

    @include laptop {
      align-items: center;
      text-align: center;
    }

    .prefix,
    .suffix {
      font-size: pxToRem(24);
      @include laptop {
        font-size: pxToRem(36);
      }
    }
    .label {
      font-size: pxToRem(48);
      letter-spacing: -3px;
      @include laptop {
        font-size: pxToRem(48);
      }
    }
  }

  &.box {
    padding: var(--space-3) var(--space-2);
  }

  &.inverted {
    .visual *,
    .title,
    .title strong,
    .description {
      color: var(--white) !important;
    }
  }
}

.flexibleWidth {
  padding-left: pxToRem(40);
  padding-right: pxToRem(40);
  margin: 0 auto;
  max-width: pxToRem(1440);

  @include laptop-large-down {
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
  }
}
