@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.datablock {
  position: relative;
}

.title {
}

.content {
  margin-top: var(--space-2);
}
