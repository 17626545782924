@import "../../../styles/mediaqueries";
@import "../../../styles/tools";
@import "../../../styles/mixins";

.tabsContent {
  position: relative;
  width: 100%;
}

.tabsDeskWrapper {
  justify-content: center;
  width: 100%;
  display: none;

  @include desktop {
    display: flex;
  }

  &.alignLeft {
    justify-content: flex-start;

    .list {
      justify-content: flex-start;
      margin-left: 0;
    }
  }

  &.alignRight {
    justify-content: flex-end;

    .list {
      justify-content: flex-end;
      margin-right: 0;
    }
  }
}

.list {
  display: inline-flex;
  position: relative;
  list-style-type: none;
  grid-gap: var(--space-2);
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: var(--white);
  @include radius("lg");
  @include soft-float;
  width: auto;
  margin: 0 auto;
  white-space: nowrap;
  padding: calc(var(--space-1) / 2);

  .item {
    z-index: 1;
  }

  .highlight {
    position: absolute;
    top: calc(var(--space-1) / 2);
    bottom: calc(var(--space-1) / 2);
    left: 0;
    width: 0;
    @include radius("lg");
    background-color: var(--tobii-link-color);
    z-index: 0;
    pointer-events: none;
  }
}

.tabsMobileWrapper {
  display: flex;
  position: relative;
  width: 100%;

  @include desktop {
    display: none;
  }

  &.alignLeft {
    margin-left: 0;
  }

  &.alignRight {
    margin-right: 0;
  }

  .header {
    background-color: var(--white);
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    gap: var(--space-1);
    transition: all 0.3s ease;
    @include radius("lg");
    @include soft-float;
    z-index: 2;

    > * {
      padding: var(--space-2) var(--space-4);
    }
  }

  .title {
    padding: calc(var(--space-1) * 1.5) var(--space-3)
      calc(var(--space-1) * 1.5) var(--space-4);
    font-weight: var(--font-weight-bold);
    font-size: pxToRem(16);
    color: var(--black);
  }

  .toggle {
    all: unset;
    position: relative;
    padding: calc(var(--space-1) * 1.5) var(--space-2);
    cursor: pointer;
    border-left: var(--border);
    display: flex;
    align-items: center;
    justify-content: center;
    @include radius-custom("none", "lg", "lg", "none");
    transition: color, background-color 0.3s ease;
    line-height: 1;

    &:focus-visible {
      outline: 2px solid var(--tobii-link-color);
      outline-offset: -2px;
      @include radius("md");
    }

    &:hover {
      color: var(--tobii-link-color);
      background-color: var(--background-3);
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--background-3);
    @include radius-custom("none", "none", "md", "md");
    @include soft-float;
    z-index: 1;
    padding-top: var(--space-2);
    margin-top: calc(var(--space-1) * -1);
    transform-origin: top center;
    will-change: transform, opacity;

    @include tablet {
      left: initial;
      right: 0;
      width: fit-content;
      max-width: pxToRem(400);
    }

    .item {
      box-shadow: 0 1px 0 0 var(--background-2);

      &:last-child {
        box-shadow: none;
      }
    }

    .tabButton {
      width: 100%;
      text-align: left;
      transition: all 0.3s ease;

      &:hover {
        background-color: var(--gray-50);
      }

      &.isActive {
        color: var(--tobii-link-color);
        background-color: var(--gray-50);
      }
    }
  }

  &.isOpen {
    .header {
      @include radius-custom("md", "md", "none", "none");

      @include tablet {
        @include radius-custom("md", "md", "none", "md");
      }
    }

    .toggle {
      background-color: var(--background-3);
      @include radius-custom("none", "md", "none", "none");
    }
  }
}

.tabButton {
  position: relative;
  z-index: 1;
  padding: calc(var(--space) * 1.5) var(--space-2);
  font-size: pxToRem(14);
  font-family: var(--font-heading-medium);
  color: var(--black);
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;

  @include xl {
    font-size: pxToRem(16);
    padding: var(--space-2) var(--space-3);
  }

  &:hover {
    color: var(--tobii-link-color);
  }

  &.isActive {
    color: var(--white);
  }
}
