@import "./styles/tools.scss";
@import "./styles/mediaqueries.scss";

// Inner spacing
.onlyTop {
  padding-bottom: 0;
  padding-top: var(--space-8);

  @include desktop {
    padding-top: var(--space-12);
  }
}

.onlyBottom {
  padding-top: 0;
  padding-bottom: var(--space-8);

  @include desktop {
    padding-bottom: var(--space-12);
  }
}

.both,
.topAndBottom {
  padding-top: var(--space-8);
  padding-bottom: var(--space-8);

  @include desktop {
    padding-top: var(--space-12);
    padding-bottom: var(--space-12);
  }
}

.bothHalf {
  padding-top: var(--space-6);
  padding-bottom: var(--space-6);
}

.none {
  padding: 0;
}

// Outer spacing
.outerOnlyTop {
  margin-top: var(--space-6);
}

.outerOnlyBottom {
  margin-bottom: var(--space-6);
}

.outerBoth {
  margin-top: var(--space-6);
  margin-bottom: var(--space-6);
}

.outerNone {
  margin-top: 0;
  margin-bottom: 0;
}
