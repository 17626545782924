@import "../../styles/tools";
@import "../../styles/mixins.scss";

.searchInput,
.input,
.searchInput_small,
.searchInput_small .input,
.searchInput_mini,
.searchInput_mini .input {
  transition: all 0.25s;
  background-color: var(--tobii-frost-blue);

  &:focus-within {
    background-color: var(--tobii-frost-blue-10b);
  }
}

.searchInput {
  display: grid;
  align-items: center;
  gap: 0.75rem;
  @include radius("lg");
  grid-template-columns: min-content minmax(pxToRem(100), auto) min-content;
  height: pxToRem(61);
  background-color: var(--tobii-frost-blue);
  padding: 0 var(--space-3);
  width: 100%;
  box-shadow: inset 0 0 0 2px var(--tobii-frost-blue-20b);

  .clear {
    background-color: transparent;
  }
}

.searchInput_small {
  padding: 0 var(--space-2);
  height: pxToRem(40);
  gap: var(--space-1) / 2;

  .input {
    padding-left: var(--space-1);
  }
}

.searchInput_mini {
  height: auto;
  padding: var(--space-1);
  gap: var(--space-1) / 2;
  border: 0;

  .input {
    font-size: pxToRem(12);
    background-color: transparent;
    padding-left: var(--space-1);
  }
}

.input {
  font-family: var(--font-body);
  font-size: pxToRem(18);
  line-height: 1;
  border: 0;
  appearance: none;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: var(--foreground-3);
  }

  // removes native clear button from IE
  &[type="search"]::-ms-clear,
  &[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  // removes native clear button in webkit
  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
}

.searchInput_small .input {
  font-size: pxToRem(12);
}

.clear {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 100ms ease;
}

.clear[disabled] {
  cursor: default;
  opacity: 0;
}
