$border-radius: (
  "none": 0,
  "xxs": 0.125rem,
  "xs": 0.25rem,
  "sm": 0.5rem,
  "md": 1rem,
  "lg": 2rem,
  "xl": 5rem,
  "2xl": 10rem,
  "full": 9999px,
);

@function radius($size) {
  @if map-has-key($border-radius, $size) {
    @return map-get($border-radius, $size);
  }
  @error "Invalid border radius size: #{$size}. Valid sizes are: #{map-keys($border-radius)}";
}

@mixin radius($size) {
  border-radius: radius($size);
}

@mixin radius-custom(
  $top-left: null,
  $top-right: null,
  $bottom-right: null,
  $bottom-left: null
) {
  $corners: (
    top-left: $top-left,
    top-right: $top-right,
    bottom-right: $bottom-right,
    bottom-left: $bottom-left,
  );

  @each $corner, $size in $corners {
    @if $size {
      border-#{$corner}-radius: if(
        type-of($size) == string,
        radius($size),
        $size
      );
    }
  }
}
