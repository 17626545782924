/* stylelint-disable */
@import "../../../styles/tools";
@import "../../../styles/mediaqueries";
@import "../../../styles/mixins";

.filterPage {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: var(--space-4);
  @include section-padding;

  &.publicationFilterPage {
    padding-bottom: var(--space-4);
  }
}

.filter,
.input,
.numberOfHits,
.sort,
.preamble,
.toggle,
.body,
.status {
  display: flex;
  flex-direction: column;
}

.toggle {
  @include desktop {
    display: none;
  }
}

.sort {
  grid-area: sort;
}

.filter {
}

.filter_visible {
  display: flex;
  max-height: 1500px;
  opacity: 1;
  transition: max-height 600ms ease, opacity 300ms ease;
}

.input {
}

.numberOfHits {
  font-size: pxToRem(12);
  color: var(--foreground-2);
}

.filter_visible + .numberOfHits {
  margin-top: var(--space-6);

  @include laptop {
    margin-top: 0;
  }
}

.preamble {
  grid-area: preamble;

  p {
    color: var(--black);
  }

  @include laptop {
    max-width: pxToRem(720);
    margin: var(--filter-gap) 0;
  }
}

.toolbar {
  display: grid;
  align-items: center;
  gap: var(--space-2);
  background-color: var(--white);
  @include radius("md");
  padding: var(--space-2);
  @include soft-float;
  grid-template-columns: 1fr;

  @include desktop {
    @include radius("xl");
    grid-template-columns: 1fr 2fr;
  }
}

.body {
  gap: var(--space-2);
}

.status {
  padding: 0 var(--space-1);
  margin-top: calc(var(--space-2) * -1);
  flex-direction: row;
  justify-content: space-between;
}

.refinementsWrapper {
  transition: all 0.25s;
  gap: var(--space-2);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > * {
    flex: 1 1 100%;
  }

  @include tablet {
    justify-content: flex-start;

    > * {
      flex: 1 1 calc(100% / 3);
    }
  }

  @include desktop {
    flex-wrap: nowrap;

    > * {
      flex: 1 1 calc(100% / 4);
    }
  }

  &.hidden {
    display: none !important;
  }
}

// Mediabank customizations
.altOne {
  @include desktop {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
