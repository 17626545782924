@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.section {
  position: relative;

  .intro {
    margin-bottom: var(--space-3);
  }
}

.contentGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  min-width: calc(100vw - var(--space-6));

  @include tablet {
    min-width: calc(50vw - var(--space-3) - var(--slider-gap));
  }

  @include laptop {
    min-width: calc(50% - 6vw);
  }

  @include xl {
    min-width: calc(100% / 3 - var(--slider-gap) - pxToRem(11));
  }

  .imageContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 4 / 3;
    object-fit: cover;
    overflow: hidden;
    @include radius("lg");
    margin-bottom: var(--space-1);

    img {
      transition: 0.3s ease-in-out;
      transition-property: transform, object-position;
    }

    &.alignleft {
      img {
        object-fit: cover;
        object-position: 20% center;
      }
    }
  }

  label {
  }

  h3 {
    transition: 0.3s ease-in-out;
  }

  p {
    font-size: pxToRem(16);
  }

  button {
    text-decoration: none;
  }

  .link {
    text-decoration: none;
    color: inherit;
    display: grid;
    grid-gap: var(--space-1);

    h3,
    p {
      margin-top: 0;
    }

    &:hover {
      h3 {
        color: var(--tobii-link-color);
      }

      .imageContainer img {
        transform: scale(1.1);
        transition: 0.3s ease-in-out;
      }
    }
  }
}

.navigation {
  position: relative;
  display: flex;
  gap: var(--space-2);
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: var(--space-4);
}
