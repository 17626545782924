@import "../../../styles/tools";
@import "../../../styles/mediaqueries";
@import "../../../styles/mixins";

.container {
  display: block;

  .imgContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    justify-content: center;

    &.narrow {
      aspect-ratio: 1;
      max-width: pxToRem(720);
      margin: 0 auto;
      width: calc(100vw - var(--gutter, var(--space-6)));
    }

    &.keepProportions {
      aspect-ratio: auto;
    }

    img {
      @include radius("lg");
    }
  }

  figure {
    margin: 0;
  }

  figcaption {
    padding-left: var(--space-2);
    margin-top: 1rem;
    font-size: pxToRem(12);
    border-left: 2px solid var(--stroke-1);
  }
}
