.DesktopSubNavigationItem {
  display: grid;
  align-items: center;
  gap: var(--space-1);
  text-decoration: none;
  position: relative;

  .content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0;

    .title {
      font-size: pxToRem(13);
      font-weight: var(--font-weight-bold);
      color: var(--foreground-1);

      @include xxl {
        font-size: pxToRem(14);
      }
    }

    .description {
      color: var(--foreground-2);
      font-size: pxToRem(12);
      margin: 0;

      @include xxl {
        font-size: pxToRem(13);
      }
    }
  }

  &.noIcon {
    grid-template-columns: 10fr;

    &.externalLink {
      grid-template-columns: 9fr 1fr;
    }
  }

  &:hover {
    text-decoration: none;

    .title,
    .description {
      color: var(--tobii-link-color);
    }

    svg path {
      fill: var(--tobii-link-color);
    }
  }
}
