@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

:export {
  iconInverted: var(--white);
}

.fourOhFour {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .inner {
    display: grid;
    grid-gap: var(--space-6);
    padding: var(--space-6) 0;

    @include desktop {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: var(--space-2);

  ul {
    display: flex;
    gap: pxToRem(14);

    li {
      a {
        display: flex;
        width: var(--space-4);
        aspect-ratio: 1 / 1;
        position: relative;
        justify-content: center;
        align-items: center;
      }
    }

    svg path {
      fill: var(--black) !important;
    }
  }
}

.visual {
  @include desktop-down {
    display: none;
  }
}

.title {
}
.description {
  margin-top: 0;
  margin-bottom: var(--space-3);
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space-1);
}

.newsLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: var(--space-2);
  padding: var(--space-2) var(--space-3);
  background-color: var(--tobii-link-color);
  border-radius: pxToRem(4);
  color: var(--white);
  text-decoration: none;
  font-weight: var(--font-weight-medium);
  transition: all 0.25s;
  font-size: pxToRem(16);
  margin-top: var(--space-3);

  &:hover {
    background-color: var(--tobii-link-color-hover);
  }
}

.imageContainer {
  position: relative;
  width: 100%;

  .figure {
    margin: 0;
  }
}
