@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .container {
    position: relative;
    display: flex;
    width: 100%;
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  &.roundedCorners {
    @include radius("lg");
    overflow: hidden;
  }
}
