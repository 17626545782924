@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.columnGrid {
  --title-color: var(--foreground-1);
  --markdown-color: var(--foreground-2);
  --description-color: var(--foreground-1);
  position: relative;

  &.roundedCorners {
    @include radius("lg");
    overflow: hidden;
  }

  .inner {
    position: relative;
    display: grid;
    grid-gap: var(--space-4);

    > * {
      width: 100%;
    }
  }

  // Width
  &.wide {
    padding-left: pxToRem(24);
    padding-right: pxToRem(24);

    @include desktop {
      padding-left: pxToRem(40);
      padding-right: pxToRem(40);
    }

    > div {
      width: 100%;
    }
  }

  h2 {
    color: var(--title-color);
  }

  .description {
    color: var(--title-color);

    a {
      color: var(--title-color);
    }
  }
}

.intro {
  h2 {
    max-width: pxToRem(960);
    font-weight: var(--font-weight-bold);
  }

  .description {
    max-width: pxToRem(720);
  }

  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.grid {
  display: grid;
  grid-gap: var(--space-6);
  grid-template-columns: 1fr;

  @include laptop {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include desktop {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    &.twoColumns {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  h3 {
    color: var(--title-color);

    &.textOnly {
      margin: 0;
    }
  }
}

.item {
  display: flex;
  grid-gap: var(--space-1);
  flex-wrap: nowrap;
  flex-direction: column;

  .imageContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 4 / 3;
    overflow: hidden;
    @include radius("lg");
    margin-bottom: var(--space-1);

    &.imageKeepProportions {
      img {
        object-fit: contain !important;
      }
    }

    iframe {
      height: 100%;
    }
  }

  h2,
  h3 {
    margin-top: 0;
  }

  &.itemImage {
  }

  &.itemVideo {
    .videoContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: var(--space-1);
    }
  }
}

.markdown {
  display: grid;
  grid-gap: var(--space-1);
  color: var(--markdown-color);

  > * {
    margin: 0;
  }

  p {
    font-size: pxToRem(16);
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-2);

  &:empty {
    display: none;
  }
}
