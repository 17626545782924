.DesktopSubNavigationSidebar {
  .header {
    display: flex;
    align-items: center;
    gap: var(--space-1);

    .title {
      font-size: pxToRem(12);
      text-transform: uppercase;
      font-weight: var(--font-weight-bold);

      @include xxl {
        font-size: pxToRem(13);
      }
    }
  }

  .list {
    margin-top: calc(var(--space-2) / 2);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--space-1);
    padding-left: var(--space-2);
  }

  .DesktopSubNavigationItem {
    margin-bottom: 0;
    color: var(--foreground-2);
    text-decoration: none;

    .content .title {
      font-weight: var(--font-weight-medium);
      font-size: pxToRem(12);

      @include xxl {
        font-size: pxToRem(13);
      }
    }
  }
}
