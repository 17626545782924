@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.speakers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: var(--space-6) var(--space-3) var(--space-12);
}

.title {
  margin-top: 0;
  font-family: var(--font-heading-regular);
  font-size: pxToRem(32);
  color: var(--black);
  margin-bottom: var(--space-3);
  text-align: center;
}

.speakerList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-4);
  margin: 0 auto;
  justify-content: center;
}

.speaker {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: var(--space-3);
  @include radius("md");
  @include cool-glow(0.5);
  transition: all 0.25s;

  &:hover {
    @include cool-glow(1);
  }

  width: 100%;
  min-width: pxToRem(280);

  @include laptop {
    max-width: pxToRem(400);
    width: calc(50% - var(--space-4));
  }

  @include desktop {
    max-width: pxToRem(440);
    width: calc(33.333% - var(--space-4));
  }

  @include xxl {
    max-width: pxToRem(480);
    width: calc(25% - var(--space-4));
  }

  .image {
    position: relative;
    width: pxToRem(80);
    height: pxToRem(80);
    min-width: pxToRem(80);
    min-height: pxToRem(80);
    border-radius: 50%;
    overflow: hidden;
  }

  .details {
    display: flex;
    gap: var(--space-2);

    .name {
      color: var(--black);
      font-weight: var(--font-weight-bold);
      line-height: 1.2;
    }

    .role {
      text-transform: uppercase;
      font-size: pxToRem(10);
      color: var(--foreground-1);
    }
  }

  .detailsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: calc(var(--space-1) * 2 / 3);

    p {
      margin: 0;
    }
  }

  .share {
    display: flex;
    gap: pxToRem(8);
    margin-top: pxToRem(4);

    div {
      width: var(--space-4);

      a,
      button {
        svg {
          width: pxToRem(14);
        }
      }
    }
  }

  .description {
    margin-top: var(--space-2);
    display: flex;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: max-height 0.2s ease;
    font-size: pxToRem(14);

    &:empty {
      display: none;
    }
  }
}

.expandButton {
  all: unset;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: var(--space-2);
  font-size: pxToRem(10);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  color: var(--foreground-1);
  cursor: pointer;

  &::before,
  &::after {
    flex: 1;
    content: "";
    height: 1px;
    background-color: var(--stroke-1);
  }

  &::before {
    margin-right: var(--space-1);
  }

  &::after {
    margin-left: var(--space-1);
  }

  .icon {
    margin-left: pxToRem(4);
    transition: transform 0.2s ease;

    &.open {
      transform: rotate(180deg);
    }
  }
}
