@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.embed {
  position: relative;
}

.inner {
  position: relative;
  display: grid;
  position: relative;
  grid-gap: var(--space-3);
}

.content {
  iframe {
    margin-bottom: -6px;
  }
}
