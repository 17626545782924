@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.header {
  height: var(--header-height);
  overflow: hidden;
  z-index: var(--z-index-header);
  width: 100%;
  display: none;
  position: fixed;
  top: var(--space-2);
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &.isOpen {
    height: 100vh;
    background-color: var(--signature);
    overflow-y: scroll;
  }

  &.stickToTheTop {
    top: 0;

    .container {
      width: 100%;
      max-width: 100%;
    }
  }

  &.tobiiGroup {
  }

  @include not-touchscreen {
    @include desktop {
      height: var(--header-height);
      overflow: visible;
      display: block;

      &.isOpen {
        height: auto;
      }
    }
  }

  .container {
    transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      max-width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.subNavOverlay {
  z-index: var(--z-index-header-sub-menu-overlay);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--black-transparent-25);
  @include backdrop-blur();

  @include desktop-down {
    display: none;
  }
}
