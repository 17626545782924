.noGutter {
  --gutter: 0;
}

.narrow,
.medium,
.wide,
.xWide,
.xxWide,
.fluid {
  margin: 0 auto;
  width: calc(100vw - var(--gutter, var(--space-6)));
}

.narrow {
  max-width: var(--width-narrow);
}

.medium {
  max-width: var(--width-medium);
}

.wide {
  max-width: var(--width-wide);
}

.xWide {
  max-width: var(--width-x-wide);
}

.xxWide {
  max-width: var(--width-xx-wide);
}

.fluid {
  width: 100%;
}

.fullHeight {
  min-height: 100vh;
}
