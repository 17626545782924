@import "../../../styles/mediaqueries";
@import "../../../styles/tools";
@import "../../../styles/mixins";

[data-item-count="5"] {
  &.layout_1 {
    @include desktop {
      grid-template-columns: 0.8fr 1.4fr 0.8fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "top-left middle top-right"
        "bottom-left middle bottom-right";
    }

    @include xxl {
      grid-template-columns: 0.7fr 1.6fr 0.7fr;
      grid-template-rows: 1fr 1fr;
    }

    .gridItem {
      &:nth-child(1) {
        @include desktop {
          grid-area: top-left;
        }
      }
      &:nth-child(2) {
        @include desktop {
          grid-area: bottom-left;
        }
      }
      &:nth-child(3) {
        @include desktop {
          grid-area: middle;
        }

        .contentInner {
          @include desktop {
            padding-bottom: var(--space-8);
          }
        }

        .title {
          @include desktop {
            font-size: pxToRem(28);
          }
        }

        .description {
          @include desktop {
            font-size: pxToRem(16);
          }
        }
      }
      &:nth-child(4) {
        @include desktop {
          grid-area: top-right;
        }
      }
      &:nth-child(5) {
        @include desktop {
          grid-area: bottom-right;
        }
      }
    }
  }

  &.layout_2 {
    @include desktop {
      grid-template-columns: 1.4fr 0.8fr 0.8fr;
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: row;
      grid-template-areas:
        "left top-middle top-right"
        "left bottom-middle bottom-right";
    }

    @include xxl {
      grid-template-columns: 1.6fr 0.7fr 0.7fr;
      grid-template-rows: 1fr 1fr;
    }

    .gridItem {
      &:nth-child(1) {
        @include desktop {
          grid-area: left;
        }

        .contentInner {
          @include desktop {
            padding-bottom: var(--space-8);
          }
        }

        .title {
          @include desktop {
            font-size: pxToRem(28);
          }
        }

        .description {
          @include desktop {
            font-size: pxToRem(16);
          }
        }
      }

      &:nth-child(2) {
        @include desktop {
          grid-area: top-middle;
        }
      }

      &:nth-child(3) {
        @include desktop {
          grid-area: top-right;
        }
      }

      &:nth-child(4) {
        @include desktop {
          grid-area: bottom-middle;
        }
      }

      &:nth-child(5) {
        @include desktop {
          grid-area: bottom-right;
        }
      }
    }
  }

  &.layout_3 {
    @include desktop {
      grid-template-columns: 0.8fr 0.8fr 1.4fr;
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: row;
      grid-template-areas:
        "top-left top-middle right"
        "bottom-left bottom-middle right";
    }

    @include xxl {
      grid-template-columns: 0.7fr 0.7fr 1.6fr;
      grid-template-rows: 1fr 1fr;
    }

    .gridItem {
      &:nth-child(1) {
        @include desktop {
          grid-area: top-left;
        }
      }

      &:nth-child(2) {
        @include desktop {
          grid-area: top-middle;
        }
      }

      &:nth-child(3) {
        @include desktop {
          grid-area: bottom-left;
        }
      }

      &:nth-child(4) {
        @include desktop {
          grid-area: bottom-middle;
        }
      }

      &:nth-child(5) {
        @include desktop {
          grid-area: right;
        }

        .contentInner {
          @include desktop {
            padding-bottom: var(--space-8);
          }
        }

        .title {
          @include desktop {
            font-size: pxToRem(28);
          }
        }

        .description {
          @include desktop {
            font-size: pxToRem(16);
          }
        }
      }
    }
  }

  &.layout_4 {
    @include desktop {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1.25fr 1fr;
      grid-auto-flow: row;
      grid-template-areas:
        "top-left top-left top-right"
        "bottom-left bottom-center bottom-right";
    }

    .gridItem {
      &:nth-child(1) {
        @include desktop {
          grid-area: top-left;
        }

        .contentInner {
          @include desktop {
            padding-bottom: var(--space-8);
          }
        }

        .title {
          @include desktop {
            font-size: pxToRem(28);
          }
        }

        .description {
          @include desktop {
            font-size: pxToRem(16);
          }
        }
      }

      &:nth-child(2) {
        @include desktop {
          grid-area: top-right;
        }
      }

      &:nth-child(3) {
        @include desktop {
          grid-area: bottom-left;
        }
      }

      &:nth-child(4) {
        @include desktop {
          grid-area: bottom-center;
        }
      }

      &:nth-child(5) {
        @include desktop {
          grid-area: bottom-right;
        }
      }
    }
  }

  &.layout_5 {
    @include desktop {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: auto auto;
      grid-template-areas:
        "top-left top-left top-left top-right top-right top-right"
        "bottom-left bottom-left bottom-middle bottom-middle bottom-right bottom-right";
    }

    .gridItem {
      &:nth-child(1) {
        @include desktop {
          grid-area: top-left;
        }
      }

      &:nth-child(2) {
        @include desktop {
          grid-area: top-right;
        }
      }

      &:nth-child(3) {
        @include desktop {
          grid-area: bottom-left;
        }
      }

      &:nth-child(4) {
        @include desktop {
          grid-area: bottom-middle;
        }
      }

      &:nth-child(5) {
        @include desktop {
          grid-area: bottom-right;
        }
      }
    }
  }
}

[data-item-count="4"] {
  &.layout_1,
  &.layout_2,
  &.layout_3,
  &.layout_4,
  &.layout_5 {
    @include desktop {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }
  }
}

[data-item-count="3"] {
  &.layout_1,
  &.layout_2,
  &.layout_3,
  &.layout_4,
  &.layout_5 {
    @include desktop {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }
  }
}

[data-item-count="2"] {
  &.layout_1,
  &.layout_2,
  &.layout_3,
  &.layout_4,
  &.layout_5 {
    @include desktop {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
    }
  }
}

[data-item-count="1"] {
  &.layout_1,
  &.layout_2,
  &.layout_3,
  &.layout_4,
  &.layout_5 {
    @include desktop {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 1fr;
    }
  }
}
