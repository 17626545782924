@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.button {
  --tiny-text: #{pxToRem(8)};
  --small-text: #{pxToRem(10)};
  --medium-text: #{pxToRem(12)};
  --large-text: #{pxToRem(16)};
  --fs: #{pxToRem(13)};
  --color: var(--black);
  --colorInverted: var(--black);
  --shadow-expansion: 0 0 0 0.625rem;
  --shadow-expansion-cta: 0 0 0 1.25rem;
  --disabled-color: rgb(56 61 86 / 40%);
  --disabled-bg: rgb(0 0 0 / 10%);
  --disabled-inverted-color: rgb(255 255 255 / 30%);
  --disabled-inverted-bg: rgb(255 255 255 / 25%);

  width: fit-content;
  height: 3.4em;
  gap: var(--space-2);
  display: flex;
  align-items: center;
  justify-self: baseline;
  color: var(--color);
  font-family: var(--font-heading-medium);
  font-size: var(--fs);
  font-weight: var(--font-weight-normal);
  text-transform: uppercase;
  position: relative;
  appearance: none;
  background: transparent;
  padding: 1.3em 1.9em;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.25s;
  text-decoration: none;

  &.small {
    --fs: var(--small-text);
    padding: 0.85em 1.3em;
    height: 2.8em;
  }

  &.medium {
    --fs: var(--medium-text);
    padding: 0.75em 1.5em;
    height: 2.8em;
  }

  &.large {
    --fs: var(--large-text);
    padding: 0.65em 1.7em;
    height: 3em;
  }

  & > span {
    display: flex;
    justify-content: space-between;
    gap: 0.5em;
    text-align: center;
  }

  span {
    transition: 0.25s;
  }

  svg {
    fill: currentColor;
    transition: fill 0.25s;
  }

  &:hover {
    transition: all 0.25s;
    text-decoration: none;
  }

  &:hover:not(:disabled) {
    transition: transform 0.25s;

    span {
      transition: transform 0.25s;
    }
  }

  /* START OF TAB FOCUS */
  &::before {
    transition: 1s 1s;
    opacity: 0;
  }

  &:hover::before {
    transition: 0s;
    opacity: 0;
  }

  /* END OF TAB FOCUS */

  &::after {
    content: "";
    position: absolute;
    color: var(--color);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 100px;
    opacity: 0.2;
  }

  // DEFAULT BUTTON
  &.default {
    --color: var(--black);
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--black);
    transition: all 0.25s;

    &::before {
      transition: 1s 1s;
      opacity: 0;
    }

    &:hover {
      --color: var(--white);
      background-color: var(--black);
      transition: all 0.25s;

      svg {
        fill: currentColor;
      }
    }

    &:hover::before {
      transition: 0s;
      opacity: 0;
    }

    &::after {
      color: var(--color);
    }

    &.disabled {
      background-color: var(--disabled-bg);
      --color: var(--disabled-color);
      box-shadow: inset 0 0 0 2px var(--disabled-color);
      cursor: not-allowed;
    }
  }

  // CTA BUTTON
  &.cta {
    background-color: var(--tobii-link-color);
    --color: var(--white);

    &:hover {
      background-color: var(--tobii-link-color-hover);
      transition: all 0.25s;
    }

    &::after {
      color: var(--tobii-link-color);
    }

    &.disabled {
      background-color: var(--disabled-bg);
      --color: var(--disabled-color);
    }
  }

  // INVERTED BUTTON
  &.inverted {
    --color: var(--white);
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--white);

    &:hover {
      --color: var(--black);
      background-color: var(--white);
      transition: all 0.25s;

      svg {
        fill: currentColor;
      }
    }

    &::after {
      color: var(--white);
    }

    &.disabled {
      cursor: not-allowed;
      background-color: var(--disabled-inverted-bg);
      box-shadow: inset 0 0 0 2px var(--disabled-inverted-color);
      --color: var(--disabled-inverted-color);
    }
  }

  // TEXT BUTTON
  &.text {
    padding: 0;
    height: initial;

    --color: var(--tobii-ocean-blue);

    font-family: var(--font-body);
    text-transform: none;
    font-size: pxToRem(12);
    font-weight: var(--font-weight-medium);
    transition: none;
    transform: none;

    &:hover:not(:disabled) span {
      transform: none;
      text-decoration: underline 5%;
    }
  }

  // TAG Button
  &.tag {
    --fs: var(--tiny-text);
    border: 2px solid var(--tobii-link-color);
    background-color: transparent;
    color: var(--tobii-link-color);
    padding: var(--space-1) calc(var(--space-1) * 1.5);
    height: auto;
    letter-spacing: 0.1em;

    &:after {
      display: none;
    }

    &:hover {
      background-color: var(--tobii-link-color);
      color: var(--white);

      span {
        color: var(--white);
        transform: none;
      }
    }

    &.gray {
      background-color: var(--tobii-light-gray);
      border: 0;
      color: var(--black);
      padding: calc(var(--space-1) / 2) var(--space-1);

      &:hover {
        background-color: var(--tobii-link-color);
        color: var(--white);

        span {
          color: var(--white);
          transform: none;
        }
      }
    }
  }

  &.glow {
    @include rainbow-glow;
  }

  &.alignLeft {
  }
  &.alignRight {
  }
  &.alignCenter {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Offset SVG when text variant is picked */
.offset {
  top: 1px;
}

:export {
  iconcolor: rgb(255 255 255);
}

.externalIcon {
  vertical-align: middle;
}
