@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.heading {
  padding: var(--space-12) 0 var(--space-6);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: var(--space-2);
  text-align: center;

  @include desktop {
    padding: var(--space-20) 0 var(--space-6);
  }
}

.details {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);

  @include laptop {
    flex-direction: row;
  }

  .parentPage {
    color: var(--tobii-link-color);
    text-decoration: none;
    font-weight: var(--font-weight-medium);

    &:hover {
      color: var(--tobii-link-color-hover);
    }
  }

  .author {
    text-transform: uppercase;
    color: var(--black);
  }
}

.share {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space-1);

  div {
    width: var(--space-4);

    a,
    button {
      svg {
        width: pxToRem(14);
      }
    }
  }
}

.detailsList {
  display: flex;
  flex-direction: column;
  grid-gap: calc(var(--space-1) / 2);

  li {
    font-size: pxToRem(12);
  }

  @include laptop {
    flex-direction: row;
    align-items: center;
    grid-gap: 0;

    li:not(:last-of-type)::after {
      content: "•";
      margin: 0 var(--space-1);
    }
  }
}
