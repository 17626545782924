@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.accordion {
  position: relative;

  &.roundedCorners {
    @include radius("lg");
    overflow: hidden;
  }

  .accordionInner {
    position: relative;
  }
}

.items {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  grid-gap: var(--space-2);
  margin: var(--space-6) var(--space-3) 0 var(--space-3);

  @include laptop-large-down {
    margin-left: 0;
    margin-right: 0;
  }
}

.item {
  .header {
    all: unset;
    @include radius("md");
    width: calc(100% - var(--space-3) * 2);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: var(--space-2);
    padding: var(--space-3);
    transition: all 0.25s;
    cursor: pointer;
    background-color: var(--black-transparent-5);

    &:hover {
      background-color: var(--black-transparent-10);
    }
  }

  .title {
    color: var(--black);
  }
  .contentInner {
    padding: var(--space-3) 0;

    @include tablet {
      padding: var(--space-3);
    }
  }

  &.expanded {
    .header {
      background-color: var(--black-transparent-10);
    }
  }

  &.inverted {
    .header {
      background-color: var(--white-transparent-10);

      &:hover {
        background-color: var(--white-transparent-25);
      }

      .title {
        color: var(--white);
      }
    }

    &.expanded {
      .header {
        background-color: var(--white-transparent-25);
      }

      .icon path {
        stroke: var(--white) !important;
      }
    }
  }
}

:export {
  iconColor: var(--black);
  iconColorInverted: var(--white);
}
