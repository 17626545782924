@import "../../styles/tools";

.items {
  margin-top: var(--space-4);
}

.item {
  margin-top: var(--space-4);

  .time {
    font-size: 20px;
    font-weight: var(--font-weight-bold);
    color: var(--foreground-1);
  }
}
