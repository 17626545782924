.DesktopSubNavigationTab {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  flex-direction: column;
}

.DesktopSubNavigationTabListWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  box-shadow: inset 0 -1px 0 var(--tobii-frost-blue-30b);
}

.DesktopSubNavigationTabList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  gap: var(--space-3);
  max-width: var(--width-x-wide);
  width: calc(100vw - var(--gutter, var(--space-6)));

  .highlight {
    position: absolute;
    bottom: 0;
    height: 4px;
    background-color: var(--tobii-link-color);
    transition: all 0.25s;
  }
}

.DesktopSubNavigationTabItem {
  font-weight: var(--font-weight-bold);
  font-size: pxToRem(13);
  padding: var(--space-2) 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  overflow: hidden;

  @include xxl {
    padding: calc(var(--space-2) * 1.25) 0;
    font-size: pxToRem(14);
  }

  &:hover,
  &.active {
    color: var(--tobii-link-color);
  }
}
