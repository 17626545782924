@import "../../../styles/tools";
@import "../../../styles/mediaqueries";

.copy {
  min-height: 1rem;

  ul,
  ol {
    li {
      position: relative;
      margin-top: 13px;
    }
  }

  ul {
    margin-left: var(--space-4);
    li {
      &::before {
        content: "";
        position: absolute;
        height: pxToRem(4.5);
        width: pxToRem(4);
        border-radius: 45%;
        background-color: var(--foreground-2);
        left: pxToRem(-16);
        top: pxToRem(13);
      }
    }
  }

  ol {
    margin: 0 0 0 var(--space-4);
    padding: 0;
    li {
      &::marker {
      }
    }
  }

  a:not([class*="Button"]) {
    color: var(--tobii-link-color);
    word-break: break-all;
  }

  // Hide the element if it has no content
  &:empty {
    display: none;
    height: 0;
  }
}
