.icon {
  display: inline;
  position: relative;
  width: var(--width);
  height: auto;
  fill: var(--color, currentColor);

  path {
    fill: var(--color, currentColor);
  }
}
