@import "../../../styles/tools";
@import "../../../styles/mixins";

.blockBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.25s;
}

.transparent {
  background-color: transparent;
}

// Solid
.default,
.black {
  background-color: var(--tobii-ocean-blue-90b);
}

.gray {
  background-color: var(--tobii-light-gray);
}

.frostBlue {
  background-color: var(--tobii-frost-blue);
}

.signature {
  background-color: var(--signature);
}

.white {
  background-color: var(--white);
}

.blue,
.gradientRadialTopBlueSignature,
.gradientRadialCenterBlueSignature,
.gradientLinearBlueSignature {
  background-color: var(--tobii-ocean-blue);
}

.oceanBlue {
  background-color: var(--tobii-ocean-blue);
}

.peach {
  background-color: var(--tobii-peach);
}

.violet {
  background-color: var(--tobii-violet);
}
