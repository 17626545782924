@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.overlay {
  position: relative;
  padding-top: var(--space-12);
  padding-bottom: var(--space-12);

  &.innerSmall {
  }
  &.innerMedium {
    padding-top: pxToRem(144);
    padding-bottom: pxToRem(144);
  }
  &.innerLarge {
    padding-top: pxToRem(192);
    padding-bottom: pxToRem(192);
  }
}

.roundedCorners {
  @include radius("lg");
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.backgroundImage {
}

.backgroundOverlay {
}

.inner {
  position: relative;
  display: flex;
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}
