@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.testimonials {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.sliderSection,
.slider {
  position: relative;
}

.swiperContainer {
  height: auto;
  padding: var(--space-2) var(--space-1);

  @include desktop {
    padding: var(--space-4) var(--space-4) var(--space-2);
  }

  :global(.swiper-wrapper) {
    display: flex;
  }

  :global(.swiper-slide) {
    opacity: 0;
    height: auto;
    display: flex;
    align-items: flex-start;
  }

  :global(.swiper-slide-active) {
    opacity: 1;
  }
}

.testimonial {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--space-3);
  background-color: var(--white);
  width: 100%;
  max-width: pxToRem(720);
  margin: 0 auto;
  @include radius("lg");
  @include cool-glow(0.5);
  transition: all 0.25s;

  @include desktop {
    padding: var(--space-8);
  }

  &:hover {
    @include cool-glow(1);
  }
}

.quoteWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--space-3);
  margin-bottom: var(--space-4);

  @include laptop {
    flex-direction: row;
  }

  .icon {
    min-width: pxToRem(48);
    --color: var(--tobii-ocean-blue-90b);
  }

  .quote {
    display: block;
    quotes: none;
    font-size: pxToRem(20);
    font-weight: var(--font-weight-medium);
    color: var(--foreground-1);
  }
}

.companyWrapper {
  display: flex;
  grid-gap: var(--space-1);
  flex-direction: column;
  flex-wrap: nowrap;
  border-top: 2px solid var(--tobii-frost-blue);
  padding-top: var(--space-4);
  margin-top: auto;

  p {
    margin: 0;
  }

  .name {
    margin-top: 0;
    font-size: pxToRem(18);
    font-weight: var(--font-weight-bold);
  }

  .name,
  .roleAndCompany {
    color: var(--foreground-1);
  }

  .roleAndCompany {
  }

  .logoWrapper {
    display: flex;
    flex-wrap: nowrap;
    @include radius("md");
    background-color: var(--white);
    padding: var(--space-2);
    position: relative;
    border: 1px solid var(--tobii-light-gray);
    @include radius("sm");
    width: var(--space-6) !important;
    max-width: var(--space-6);
    height: auto;

    @include xxl {
      width: var(--space-8) !important;
      max-width: var(--space-8);
    }
  }
}

.navigation {
  position: relative;
  display: flex;
  gap: var(--space-2);
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: var(--space-4);
}

.buttons {
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--space-2);
  margin-top: var(--space-2);

  &:empty {
    display: none !important;
  }
}
