@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.logoList {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  flex-direction: column;
  max-width: 100vw;
  overflow: hidden;
  grid-gap: var(--space-3);
}

.logo {
  background-color: var(--white);
  padding: var(--space-1) var(--space-2);
  @include radius("md");
  position: relative;

  .logoInner {
    justify-content: center;
    aspect-ratio: 16 / 9;
    height: var(--space-7) !important;
    max-height: var(--space-7);
    max-width: unset !important;
    flex: 1;
    display: flex;
    position: relative;
  }
}

.marquee {
  .logo {
    margin-right: var(--space-2);
  }
}

.listWrapper {
  margin: 0 auto;
}

.list {
  display: flex;
  gap: var(--space-2);
  justify-content: center;
  flex-wrap: wrap;
}

.featured {
  display: flex;
  flex-direction: column;

  .listWrapper {
  }
}
