@import "../../styles/tools";
@import "../../styles/mixins";

.filterCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white-transparent-75);
  @include backdrop-blur();
  @include cool-glow(0.5);
  @include radius("md");
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: var(--white);
    @include cool-glow(1);

    .title a {
      color: var(--tobii-link-color);
    }

    .imageContainer img {
      transform: scale(1.1);
      transition: 0.3s ease-in-out;
    }
  }
}

.imageContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 21 / 9;
  overflow: hidden;
  @include radius-custom("md", "md", "none", "none");

  img {
    transition: 0.3s ease-in-out;
    transition-property: transform, object-position;
  }

  &.alignleft {
    img {
      object-fit: cover;
      object-position: 20% center;
    }
  }

  @include laptop {
    aspect-ratio: 16 / 9;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-self: flex-start;
  gap: var(--space-1);
  padding: var(--space-3);

  > * {
    margin: 0;
    padding: 0;
  }

  .title {
    a {
      color: var(--foreground-1);
      text-decoration: none;
      transition: 0.3s ease-in-out;
    }
  }

  .body {
    word-break: break-word;
    color: var(--foreground-2);

    p {
      margin: 0;
    }

    & > p:first-child {
      font-size: pxToRem(16);
    }
  }
}

.details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--foreground-2);
  padding: 0 var(--space-3) var(--space-3) var(--space-3);

  li {
    font-size: pxToRem(12);
  }

  li + li {
    &::before {
      content: "•";
      margin: 0 var(--space-1);
    }
  }
}

.tag {
  @include tag;
}
