@import "../../styles/tools";
@import "../../styles/mixins";
.ImageWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  grid-gap: var(--space-2);
  margin-bottom: var(--space-2);
}

.noPadding {
  padding: 0;
}

.title {
  margin: 0;
}

.tags {
  ul {
    padding-top: 0 !important;
  }
}

.imageContainer {
  @include litterbox;
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;

  img {
    object-fit: contain !important;
  }
}