@import "../../styles/tools";
@import "../../styles/mixins";

.searchHits {
  padding-bottom: var(--space-6);
}

.searchHits,
.hits,
.hit {
  display: flex;
  flex-direction: column;
}

.total {
  font-size: pxToRem(12);
  text-align: right;
}

.hits {
  list-style: none;
  padding: 0;
  margin: 1rem 0 0;
  gap: var(--space-4);
}

.hit {
  background-color: var(--white-transparent-75);
  @include backdrop-blur();
  @include cool-glow(0.5);
  @include radius("md");
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  transition: 0.3s ease-in-out;
  @include radius("md");

  &:hover {
    background-color: var(--white);
    @include cool-glow(1);

    .imageContainer img {
      transform: scale(1.1);
      transition: 0.3s ease-in-out;
    }
  }

  @include desktop {
    grid-template-columns: 2fr 5fr;
  }
}

.imageContainer {
  display: none;

  @include desktop {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include radius-custom("md", "none", "none", "md");
  }

  img {
    object-fit: cover;
    transition: 0.3s ease-in-out;
    transition-property: transform, object-position;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
  padding: var(--space-3);
}

.body {
  margin: 0;
  font-size: pxToRem(16);
}

.hit {
  mark {
    background-color: var(--tobii-link-color);
    color: var(--white);
  }

  &:hover {
    .title a {
      color: var(--tobii-link-color);
    }
  }
}

.title {
  margin: 0;
  font-weight: var(--font-weight-medium);
  font-size: pxToRem(20);
  font-family: var(--font-body);
  line-height: 1.2;

  a {
    transition: 0.3s ease-in-out;
    text-decoration: none;
    color: var(--black);
  }
}

.tags {
  display: flex;
  gap: var(--space-1);
  list-style: none;
}

.pagination {
  margin-top: var(--space-4);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: pxToRem(26);
}
