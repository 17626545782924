/* stylelint-disable */
@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.sliderWrapper {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  clip-path: inset(0 0 0 0);
}

.mySwiper {
  position: relative;
  width: 100%;
  height: 50vh;
  min-height: pxToRem(320);
  overflow: hidden !important;
  box-sizing: inherit;

  @include laptop {
    height: 60vh;
  }

  @include laptop {
    height: 70vh;
  }

  @include not-touchscreen {
    @include laptop {
      min-height: pxToRem(448);
    }
  }

  img,
  video {
    object-fit: cover;
    width: 100%;
    height: 50%;
  }
}

@include touchscreen {
  @media (orientation: landscape) {
    .swiper-wrapper {
      min-height: pxToRem(320);
    }
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: var(--space-2);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.contentLeft {
    @include xxl {
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
    }
  }

  .label,
  .title,
  .description {
    color: var(--black);
    margin-top: 0;
    padding-left: var(--space-2);
    padding-right: var(--space-2);

    @include xxl {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .label {
    font-family: var(--font-heading-medium);
    font-size: pxToRem(12);
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: pxToRem(2);
  }

  .title {
    max-width: pxToRem(960);
    line-height: 1;
    font-family: var(--font-heading-medium);
    font-weight: var(--font-weight-normal);
    font-size: pxToRem(40);

    @include laptop {
      font-size: pxToRem(60);
    }

    @include touchscreen {
      max-width: 100%;
      font-size: pxToRem(40);

      @include laptop {
        font-size: pxToRem(60);
      }
    }
  }

  .description {
    font-family: var(--font-body);
    max-width: pxToRem(720);
    font-weight: var(--font-weight-medium);
    line-height: 1.2;

    @include laptop {
      line-height: 1.5;
    }

    @include touchscreen {
      font-size: pxToRem(16);

      @include laptop {
        font-size: pxToRem(18);
      }
    }
  }

  .buttons {
    display: flex;
    gap: var(--space-2);
    flex-direction: row;
    place-items: flex-start;
    justify-content: left;

    &:empty {
      display: none;
    }
  }

  a {
    height: auto !important;
  }

  &.inverted {
    .label,
    .title,
    .description {
      color: var(--white);
    }
  }
}

// Slide Item
.slideItem {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

// Slide Image
.slideImage {
  .imageContainer {
  }
}

// Slide Video
.slideVideo {
  .videoContainer {
    width: 100%;
    height: 100%;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@include touchscreen {
  @media (orientation: landscape) {
    @media (max-height: 767px) {
      .mySwiper {
        height: 85vh;
      }
    }
  }

  @media (orientation: portrait) {
    @media (max-width: 1024px) {
      .mySwiper {
        height: 50vh;
      }
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.overlayNone {
  background-color: transparent;
}
.overlay01 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
}
.overlay02 {
  background: linear-gradient(
    0deg,
    rgba(14, 16, 62, 1) 0%,
    rgba(14, 16, 62, 0) 100%
  );
}
.overlay03 {
  background: linear-gradient(
    0deg,
    rgba(0, 91, 148, 1) 0%,
    rgba(0, 91, 148, 0) 100%
  );
}
