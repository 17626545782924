@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.localAlert {
  --background-color: var(--background-3);
  --title-color: var(--black);
  --paragraph-color: var(--black);
  --icon-filter: none;

  &.red {
    --background-color: var(--red);
  }

  &.black {
    --background-color: var(--black);
  }

  &.signature {
    --background-color: var(--signature);
  }

  &.red,
  &.black,
  &.signature {
    --title-color: var(--white);
    --paragraph-color: var(--white);
    --icon-filter: invert(1);
  }

  .content {
    padding: var(--space-6) var(--space-3);
    background-color: var(--background-color);
    @include radius("lg");
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    grid-gap: var(--space-2);
    text-align: center;
  }

  .title,
  .description {
    margin: 0;
  }

  h3 {
    color: var(--title-color);
  }

  p {
    color: var(--paragraph-color);
    font-size: pxToRem(16);
  }

  .icon {
    display: none;

    @include laptop {
      display: flex;
      filter: var(--icon-filter);
    }
  }
}
