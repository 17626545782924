$tobii-peach: #fd6f60;
$tobii-violet: #8018d8;
$tobii-ocean-blue: #0071eb;
$tobii-frost-blue: #f2f8ff;
$tobii-shadow: #000000;
$tobii-shadow-soft: #1a1a1a;

// Utility function to convert color to RGBA
@function to-rgba($color, $opacity) {
  @return rgba(red($color), green($color), blue($color), $opacity);
}

@mixin warm-glow($intensity: 1) {
  box-shadow: 0 0 16px to-rgba($tobii-peach, 0.15 * $intensity),
    0 0 40px to-rgba($tobii-violet, 0.1 * $intensity);
}

@mixin cool-glow($intensity: 1) {
  box-shadow: 0 0 16px to-rgba($tobii-ocean-blue, 0.15 * $intensity),
    0 0 32px to-rgba($tobii-frost-blue, 0.2 * $intensity);
}

@mixin brand-glow($intensity: 1) {
  box-shadow: 0 0 16px to-rgba($tobii-peach, 0.12 * $intensity),
    0 0 32px to-rgba($tobii-violet, 0.08 * $intensity),
    0 0 48px to-rgba($tobii-ocean-blue, 0.05 * $intensity);
}

@mixin interactive-glow($intensity: 1) {
  transition: box-shadow 0.4s ease;

  box-shadow: 0 0 16px to-rgba($tobii-ocean-blue, 0.1 * $intensity),
    0 0 32px to-rgba($tobii-peach, 0.05 * $intensity);

  &:hover {
    box-shadow: 0 0 24px to-rgba($tobii-peach, 0.15 * $intensity),
      0 0 48px to-rgba($tobii-violet, 0.1 * $intensity);
  }
}

@mixin rainbow-glow($intensity: 1, $duration: 8s) {
  animation: tobiiRainbowGlow #{$duration} infinite;

  @keyframes tobiiRainbowGlow {
    0% {
      box-shadow: 0 0 24px to-rgba($tobii-peach, 0.2 * $intensity),
        0 0 48px to-rgba($tobii-peach, 0.1 * $intensity);
    }
    33% {
      box-shadow: 0 0 24px to-rgba($tobii-violet, 0.2 * $intensity),
        0 0 48px to-rgba($tobii-violet, 0.1 * $intensity);
    }
    66% {
      box-shadow: 0 0 24px to-rgba($tobii-ocean-blue, 0.2 * $intensity),
        0 0 48px to-rgba($tobii-ocean-blue, 0.1 * $intensity);
    }
    100% {
      box-shadow: 0 0 24px to-rgba($tobii-peach, 0.2 * $intensity),
        0 0 48px to-rgba($tobii-peach, 0.1 * $intensity);
    }
  }
}

@mixin soft-float($intensity: 1) {
  box-shadow: 0 2px 8px to-rgba($tobii-shadow, 0.1 * $intensity),
    0 4px 16px to-rgba($tobii-shadow-soft, 0.05 * $intensity);
}

@mixin soft-float-hover($intensity: 1) {
  box-shadow: 0 4px 12px to-rgba($tobii-shadow, 0.12 * $intensity),
    0 8px 24px to-rgba($tobii-shadow-soft, 0.06 * $intensity);
}

// Preset mixins
@mixin cta-glow {
  @include warm-glow(1.2);
}

@mixin card-glow {
  @include cool-glow(0.8);
}

@mixin nav-glow {
  @include interactive-glow(0.7);
}

@mixin hero-glow {
  @include rainbow-glow(1.3, 8s);
}

@mixin premium-glow {
  @include brand-glow(1.1);
}

@mixin card-float {
  @include soft-float(0.8);
}
