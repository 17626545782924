@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.ListPressReleases {
  position: relative;
  padding: var(--space-8) 0;
  min-height: 500px;
}

.tabsWrapper {
  margin-bottom: var(--space-4);
}

.iframe {
  width: 100%;
  min-height: 500px;
  border: none;
  transition: height 0.2s ease-in-out;
  border: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.iframeSidebarContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-4);

  @include xl {
    grid-template-columns: calc(100% - 400px) 400px;
    max-width: 100%;
    min-width: min-content;
  }
}

.iframeWrapper {
  border: none;
  min-width: 0;
  width: 100%;

  &[style*="opacity: 0"] {
    min-height: 500px;
  }
}

.sidebarWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);

  @include xl {
    width: 400px;
    flex-shrink: 0;
  }
}
