@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.interactiveGrid,
.interactiveGridInner {
  position: relative;
}

.interactiveGridInner {
  margin-top: var(--space-4);
}

// Mobile Grid
.mobileGrid {
  display: grid;
  flex-wrap: nowrap;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: var(--space-4);
  justify-content: center;

  &[data-columns="2"] {
    grid-template-columns: repeat(2, 1fr);

    & > *:last-child:nth-child(odd) {
      grid-column: 1 / -1; // Make last item span full width when odd numbered
    }
  }

  &[data-columns="3"] {
    grid-template-columns: repeat(3, 1fr);

    & > *:last-child:nth-child(3n-1) {
      grid-column: 2 / -1; // Last item spans 2 columns when 2 items in last row
    }
    & > *:last-child:nth-child(3n-2) {
      grid-column: 1 / -1; // Last item spans all columns when alone in last row
    }
  }

  &[data-columns="4"] {
    grid-template-columns: repeat(4, 1fr);

    & > *:last-child:nth-child(4n-2),
    & > *:last-child:nth-child(4n-1) {
      grid-column: span 2; // Last items span 2 columns each when 2 missing
    }
    & > *:last-child:nth-child(4n-3) {
      grid-column: 1 / -1; // Last item spans all columns when 3 missing
    }
  }
}

.desktopGrid {
  display: grid;
  gap: var(--space-4);

  .row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    overflow: hidden;
    gap: var(--space-4);
    width: 100%;
    min-height: pxToRem(320);

    .cardNonTouch {
      flex-basis: 100%;
      max-width: 100%;

      &:hover {
        z-index: 1;
      }
    }
  }

  &[data-items-per-row="2"] .row {
    .cardNonTouch {
      flex-basis: 50%;
      max-width: 50%;

      &:hover {
        flex-basis: 90%;
        max-width: 90%;
      }
    }
  }

  &[data-items-per-row="3"] .row {
    .cardNonTouch {
      flex-basis: 33.33%;
      max-width: 33.33%;

      &:hover {
        flex-basis: 80%;
        max-width: 80%;
      }
    }
  }

  &[data-items-per-row="4"] .row {
    .cardNonTouch {
      flex-basis: 25%;
      max-width: 25%;

      &:hover {
        flex-basis: 70%;
        max-width: 70%;
      }
    }
  }
}

.card {
  @include radius("lg");
  position: relative;
  overflow: hidden;
  transition: all 0.7s ease-in-out;
  will-change: flex-basis, transform, background-color;

  .visualContainer {
    @include position(absolute, 0, 0, 0, 0);
    transform-origin: center center;
    inset: 0;
    z-index: 0;
    overflow: hidden;

    img,
    video,
    .videoWrapper {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform-origin: center center;
      will-change: transform, opacity;
    }

    .videoWrapper {
      .loader {
        @include position(absolute, 50%, 50%, 0, 0);
        transform: translate(-50%, -50%);
      }
    }
  }

  .contentContainer {
    position: relative;
    padding: var(--space-4);
    gap: var(--space-2);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .title {
    font-family: var(--font-heading-medium);
    font-weight: var(--font-weight-medium);
    font-size: pxToRem(20);
    color: var(--black);
  }

  &.inverted {
    .title {
      color: var(--white);
    }
  }
}

.cardNonTouch {
  position: relative;
  overflow: hidden;
  min-height: pxToRem(360);
  transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: flex-basis, transform, background-color;

  .defaultContent,
  .expandedContent {
    position: absolute;
    inset: 0;
    z-index: 2;
  }

  .defaultContent {
    .contentContainer {
      height: 100%;
      padding: var(--space-4);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .expandedContent {
    position: absolute;
    inset: 0;
    z-index: 2;
    visibility: visible;
    pointer-events: auto;
    background-color: rgb(from var(--black) r g b / 0.66);

    .contentContainer {
      height: 100%;
      padding: var(--space-4);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: var(--space-2);
    }
  }

  &.inverted {
    .expandedContent {
      .title {
        color: var(--white);
      }
    }
  }
}

.cardTouch {
  min-height: pxToRem(180);

  @include laptop {
    min-height: pxToRem(360);
  }

  .description {
    display: none;

    @include laptop {
      display: flex;
    }
  }

  .contentContainer {
    @include position(absolute, 0, 0, 0, 0);
    justify-content: flex-end;
    padding: var(--space-3);
  }
}
