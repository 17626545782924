@import "../../../styles/tools";
@import "../../../styles/mediaqueries";
@import "../../../styles/mixins";

:export {
  iconColorDefault: var(--tobii-link-color);
  iconColorInverted: var(--white);
}

.sliderButton {
  width: var(--space-6);
  border: 2px solid var(--tobii-link-color);
  @include radius("full");
  aspect-ratio: 1;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  position: relative;

  &:hover,
  &:active {
    border-color: var(--tobii-link-color-hover);
    background-color: var(--tobii-link-color-hover);

    svg {
      --color: var(--white) !important;
    }
  }

  span {
    display: flex;
    justify-content: center;

    svg {
      transition: 0.3s ease-in-out;
    }
  }

  &.prevButton {
    svg {
      right: 1px;
    }
  }

  &.nextButton {
    svg {
      right: -1px;
    }
  }

  &.inverted {
    border-color: var(--white);

    svg {
    }

    &:hover,
    &:active {
      background-color: var(--white);

      svg {
        --color: var(--black) !important;
      }
    }
  }

  &.floating {
    z-index: 10;
    display: none;
    position: absolute;
    background-color: var(--white);
    top: 50%;

    &:hover,
    &:active {
      background-color: var(--tobii-link-color);
    }

    @include not-touchscreen {
      @include laptop {
        display: block;
      }
    }

    @include touchscreen {
      @include desktop {
        display: block;
      }
    }

    &.prevButton {
      left: -16px;

      @media (min-width: 1601px) {
        left: -5%;
      }
    }

    &.nextButton {
      right: -16px;

      @media (min-width: 1601px) {
        right: -5%;
      }
    }
  }
}
