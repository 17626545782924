@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.featuredHero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: pxToRem(320);
  color: var(--foreground-i1);
  overflow: hidden;

  padding: var(--space-6) 0;

  @include desktop {
    padding: var(--space-20) 0 var(--space-16);
  }

  ul {
    color: var(--foreground-i2);
  }
}

.imageContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--overlay);
  opacity: 0.5;
}

.content {
  position: relative;
  display: grid;
  gap: var(--space-2);

  > * {
    margin: 0;
  }
}

.label {
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: pxToRem(10);
  line-height: pxToRem(14);
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.title {
  color: var(--white);
  max-width: pxToRem(960);
  line-height: 1.1;

  @include tablet {
    font-size: pxToRem(40);
  }
}

.preamble {
  max-width: pxToRem(720);
  font-family: var(--font-body);
}

.author {
  color: var(--foreground-i1);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-2);
}
