@import "../../../../styles/tools";
@import "../../../../styles/mediaqueries";
@import "../../../../styles/mixins";
@import "DesktopSubNavigationTab.module.scss";
@import "DesktopSubNavigationPanel.module.scss";
@import "DesktopSubNavigationSidebar.module.scss";
@import "DesktopSubNavigationCTA.module.scss";
@import "DesktopSubNavigationItem.module.scss";

.desktopMenu {
  color: var(--signature);
  display: none;
  height: var(--header-height);
  @include radius("2xl");
  @include soft-float;
  padding: 0 var(--space-3);
  transform: translateZ(0);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  @include desktop {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    z-index: 2;
  }

  .logo {
    width: pxToRem(64);

    svg {
      width: pxToRem(64);
    }
  }

  .logoCorporate {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: pxToRem(4);
    text-decoration: none;

    span {
      text-transform: uppercase;
      font-size: pxToRem(10);
      color: var(--white);
      text-decoration: none;
      font-family: var(--font-heading-medium);
    }
  }

  &.stickToTheTop {
    border-radius: 0;
    height: var(--header-mini-height);
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav {
  position: relative;
  display: none;
  height: 100%;

  @include laptop {
    display: block;
  }

  .navList {
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
    gap: var(--space-1);
  }

  .highlight {
    position: absolute;
    @include radius("lg");
    background-color: var(--tobii-link-color);
    pointer-events: none;
    z-index: -1;
    top: pxToRem(9);
    bottom: pxToRem(9);
    will-change: left, width, opacity;
    transform: translateZ(0);
  }

  .navItem {
    position: relative;

    .navItemLink {
      display: flex;
      align-items: center;
      padding: calc(var(--space-1) * 1.5) var(--space-2);
      font-family: var(--font-heading-medium);
      color: var(--black);
      text-decoration: none;
      transition: color 0.25s ease;
      cursor: pointer;
      position: relative;
      font-size: pxToRem(16);
      @include radius("lg");

      &:hover {
      }

      &.directLink {
        &:hover {
          color: var(--tobii-link-color);
          background-color: var(--tobii-frost-blue-10b);
        }
      }

      &:not(.directLink) {
        &:hover {
          color: var(--tobii-link-color);
        }
      }
    }

    &.isActive {
      .navItemLink {
        color: var(--white);

        &:hover {
          color: var(--white);
        }

        span {
          svg {
            path {
              fill: var(--white) !important;
            }
          }
        }
      }
    }
  }
}

.tobiiGroup {
  color: var(--white);
  background-color: var(--signature);

  .navItem {
    .navItemLink {
      color: var(--white);

      &:hover {
        color: var(--white-transparent-75);
      }

      &.directLink {
        &:hover {
          color: var(--white);
          background-color: var(--white-transparent-10);
        }
      }

      &:not(.directLink) {
        &:hover {
          color: var(--white-transparent-75);
        }
      }
    }

    &.isActive {
      .navItemLink {
        color: var(--signature);

        &:hover {
          color: var(--signature);
        }

        span {
          svg {
            path {
              fill: var(--signature) !important;
            }
          }
        }
      }
    }
  }

  .highlight {
    background-color: var(--white);
  }
}

.langAndSearch {
  display: none;

  @include laptop {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--space-2);
  }

  @include xxl {
    gap: var(--space-3);
  }

  button {
    all: unset;
    display: flex;
    cursor: pointer;
  }
}

.navItem:hover {
  .subNav {
    display: grid;
    opacity: 1;
  }
}

.externalIcon {
  padding-top: 2px;
  margin-left: pxToRem(5);
}

.searchIcon {
  line-height: 0;

  svg {
    --color: var(--black);
  }
}

/*******************************/
/* Desktop Sub Navigation*/
/*******************************/
.DesktopSubNavigationWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: var(--z-index-header-sub-menu);
  width: 100%;
  max-width: calc(100% - var(--space-12));
  margin: 0 auto;
  @include radius-custom("none", "none", "md", "md");
  padding: 2px var(--space-3) 0;
  box-shadow: inset 0 2px 0px var(--tobii-frost-blue-20b);
  background-color: var(--tobii-frost-blue);

  @include xxl {
    max-width: calc(100% - var(--space-24));
  }

  @include xxxl {
    max-width: var(--width-x-wide);
  }
}

//Hardware
.DesktopSubNavigation {
}

:export {
  iconcolor: var(--signature);
  iconColorInverted: var(--white);
  iconColorSubGroupHeader: var(--tobii-link-color);
}
