@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.techSpecs {
  position: relative;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  grid-gap: var(--space-3);

  @include desktop {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.expandButton {
  all: unset;
  display: flex;
  gap: var(--space-1);
  cursor: pointer;
  text-transform: uppercase;
  color: var(--black);
  font-size: pxToRem(10);
  position: relative;
}

.expandable {
  .titleContainer {
    all: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - var(--space-3) * 2);
    padding: var(--space-3);
    font-weight: var(--font-weight-bold);
    color: var(--black);
    @include radius("md");
    cursor: pointer;
    transition: all 0.25s;
    background-color: var(--black-transparent-5);

    &:hover {
      background-color: var(--black-transparent-10);
    }

    &:empty {
      padding: 0;
    }

    &.open,
    &.single {
      background-color: var(--black-transparent-10);
    }
  }

  .title {
    color: var(--black);
    font-weight: var(--font-weight-bold);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    max-height: 0;
    opacity: 0;
    transition: all 0.2s ease;
    overflow-x: auto;
    overflow-y: hidden;

    @include tablet {
      padding: 0 var(--space-3);
    }

    &.isExpanded {
      &:not(:last-child) {
        margin-bottom: var(--space-3);
      }
    }

    &.isSingle {
      max-height: none;
      opacity: 1;
    }

    &::-webkit-scrollbar {
      height: pxToRem(4);
      border-radius: pxToRem(5);
      background-color: var(--stroke-i1);
    }

    &::-webkit-scrollbar-thumb {
      height: pxToRem(4);
      border-radius: pxToRem(5);
      background-color: var(--foreground-i1);
    }
  }
}

.tables {
  display: grid;

  @include laptop {
    grid-template-columns: 1fr 1fr;
    gap: var(--space-6);
    grid-row-gap: var(--space-2);
  }
}

.table {
  border-collapse: collapse;
  align-self: start;
  width: 100%;
  margin-bottom: pxToRem(40);
}

.tableRow {
  th {
    width: 114px;
    color: var(--black);
    font-weight: var(--font-weight-bold);
    text-align: left;

    @include laptop {
      width: 216px;
    }

    p {
      strong {
        color: var(--black) !important;
      }
    }
  }

  th,
  td {
    min-width: pxToRem(165);
    font-size: pxToRem(14);
    vertical-align: top;
    padding: var(--space-2) pxToRem(40) var(--space-2) 0;
    border-bottom: 1px solid var(--black-transparent-25);
    white-space: pre-line;

    p {
      margin-top: 0.5rem;

      strong {
        color: var(--black);
      }
    }

    p:first-child {
      margin-top: 0;
    }
  }

  td {
    color: var(--black);

    &:last-of-type {
      padding-right: 0;
    }
  }

  &:first-of-type {
    th,
    td {
      padding-top: var(--space-3);
    }
  }
}

.techSpec {
  display: flex;
  grid-gap: var(--space-3);
  flex-wrap: nowrap;
  flex-direction: column;
  position: relative;
  margin-top: var(--space-3);

  @include tablet {
    margin-top: var(--space-6);
  }

  h3,
  .text,
  strong,
  a {
    color: var(--black);
  }

  h3 {
    margin-top: 0;
  }

  .text {
    margin-bottom: 0;
    @include laptop {
      max-width: pxToRem(720);
    }
  }

  a {
    text-decoration: underline;
  }
}

.tableNotes {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  font-size: pxToRem(12);
  margin-top: pxToRem(-16);
  margin-bottom: var(--space-2);
  padding-left: 0;
  list-style-type: none;
  max-width: pxToRem(600);
  counter-reset: item;

  .tableNote {
    color: var(--black);

    strong {
      color: var(--black);
    }

    a {
      color: var(--black) !important;
      text-decoration: underline;
    }

    // If there is a list, make sure it is properly aligned
    ol,
    ul {
      padding: 0;
      margin: 0;
      list-style-position: inside;
    }
  }
}

.inverted {
  .expandable {
    .titleContainer {
      background-color: var(--white-transparent-10);
      color: var(--white);

      &:hover {
        background-color: var(--white-transparent-25);
      }

      &.open,
      &.single {
        background-color: var(--white-transparent-25);

        .icon path {
          stroke: var(--white) !important;
        }
      }
    }
  }
  .tableRow {
    th,
    td,
    p,
    strong {
      color: var(--white);
    }

    th,
    td {
      border-bottom: 1px solid var(--white-transparent-25);
    }
  }

  .techSpec {
    h3,
    .text,
    strong,
    a {
      color: var(--white);
    }
  }

  .tableNotes {
    .tableNote,
    strong {
      color: var(--white);
    }

    a {
      color: var(--white) !important;
    }
  }

  .expandButton {
    color: var(--white);

    &.open .icon path {
      stroke: var(--white) !important;
    }
  }
}

:export {
  iconColor: var(--black);
  iconColorInverted: var(--white);
}
