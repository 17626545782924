@import "../../styles/tools";
@import "../../styles/mixins";
@import "../../styles/mediaqueries";

.startCta {
  padding: var(--space-12) 0;
  background-color: var(--foreground-i1);

  .wrapper {
    display: flex;
    gap: var(--space-3);
    flex-direction: column;
    opacity: 0;
    transition: opacity 1s ease;

    &.noFadeIn {
      opacity: 1;
      transition: none;
    }

    &.inViewport {
      opacity: 1;
    }
  }

  .title {
    font-family: var(--font-heading-medium);
    font-size: pxToRem(44);
    text-align: center;

    @include laptop {
      font-size: pxToRem(68);
    }
  }

  .preamble {
    text-align: center;
  }

  .buttons {
    display: flex;
    gap: var(--space-2);
    justify-content: center;
    flex-wrap: wrap;
  }

  .image {
    margin-top: pxToRem(40);

    @include laptop {
      margin-top: pxToRem(72);
    }
  }
}
