@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.eventCalendar {
  @include section-padding;
}
.eventCalendar + .eventCalendar {
  padding-top: 0;
}
.preamble {
  color: var(--foreground-1);
}

.list {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-4);
}

.event {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  padding-bottom: var(--space-4);

  @include tablet {
    flex-direction: row;
    gap: pxToRem(40);
  }

  + .event {
    padding-top: var(--space-4);
    border-top: 1px solid var(--stroke-1);
  }
}

.date {
  &.passedDate {
    background-color: var(--background-2);
    color: var(--foreground-2);
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: pxToRem(20) 0;
  background-color: var(--tobii-ocean-blue-90b);
  color: var(--white);

  @include tablet {
    min-width: pxToRem(120);
  }

  .day {
    font-size: pxToRem(20);
    font-weight: var(--font-weight-bold);

    @include tablet {
      font-size: pxToRem(32);
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: pxToRem(12);

  a {
    color: var(--black);
    font-size: pxToRem(20);
    font-weight: var(--font-weight-bold);
    transition: 0.3s ease-in-out;

    &:hover {
      color: var(--tobii-link-color);
    }
  }

  .title {
    text-decoration: none;
    text-decoration-thickness: pxToRem(1);
    text-decoration-color: var(--foreground-1);
  }

  .description {
    margin: 0;
    font-size: pxToRem(16);
  }

  .details {
    display: flex;
    align-items: flex-start;
    gap: var(--space-2);

    @include tablet {
      flex-direction: row;
    }
  }

  .tag {
    @include tag;
  }

  .location {
    color: var(--foreground-1);
    margin-top: 0;
    line-height: pxToRem(22);
  }
}
