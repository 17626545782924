@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.details {
  padding: var(--space-3);
  background-color: var(--tobii-ocean-blue-90b);
  color: var(--white);
  @include radius("md");

  &.withoutHero {
    margin-top: var(--space-3);
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: var(--space-2);
  }
}

.detail,
.document {
  display: flex;
  gap: var(--space-2);
  text-decoration: none;

  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  p {
    margin: 0;
    font-size: pxToRem(12);
    line-height: pxToRem(16.34);
  }

  .title {
    font-weight: var(--font-weight-bold);
  }

  .subtitle {
    color: var(--foreground-i2);
  }
}

.detail {
  // To make black icon white
  img {
    filter: invert(100%);
  }
}

.relatedDocuments {
  background-color: var(--background-3);
  color: var(--foreground-1);

  h2 {
    color: var(--foreground-1);
  }

  .subtitle {
    color: var(--foreground-2);
  }
}

.document {
  .title {
    color: var(--foreground-1);
  }
}

:export {
  iconcolor: var(--foreground-i1);
  relateddocumenticoncolor: var(--foreground-1);
}
