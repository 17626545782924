@import "../../styles/tools";
@import "../../styles/mediaqueries";

.alert {
  --background-color: var(--background-3);
  --title-color: var(--foreground-1);
  --paragraph-color: var(--foreground-2);
  --button-text-color: var(--foreground-1);
  --icon-filter: none;

  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-global-alerts);
  display: flex;
  border-bottom: var(--border);

  &.red {
    --background-color: var(--red);
  }

  &.black {
    --background-color: var(--black);
  }

  &.signature {
    --background-color: var(--signature);
  }

  &.red,
  &.black,
  &.signature {
    --title-color: var(--foreground-i1);
    --paragraph-color: var(--foreground-i2);
    --button-text-color: var(--foreground-i1);
    --icon-filter: invert(1);
  }

  padding: var(--space-4) 0;
  background-color: var(--background-color);

  .wrapper {
    display: flex;
    align-items: flex-start;
    gap: var(--space-3);
  }

  p {
    margin: 0;
    color: var(--paragraph-color);
    line-height: pxToRem(16);
    font-size: pxToRem(12);

    &.marginBottom {
      margin-bottom: var(--space-2);
    }
  }

  .closeButton {
    all: unset;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--space-1);
  }

  .expandableContent {
    max-height: 0;
    opacity: 0;
    transition: all 0.2s ease;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .expandButton {
    all: unset;
    display: flex;
    align-items: center;
    gap: pxToRem(5);
    margin-top: var(--space-1);
    cursor: pointer;
    text-transform: uppercase;
    color: var(--button-text-color);
    font-size: pxToRem(10);
    letter-spacing: 0.05em;

    .caret {
      transition: transform 0.2s ease;

      &.rotated {
        transform: rotate(180deg);
      }
    }
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    gap: var(--space-1);

    p {
      margin: 0;
      font-weight: var(--font-weight-bold);
      color: var(--title-color);
      font-size: pxToRem(16);
    }
  }

  .icon {
    display: none;

    @include laptop {
      display: flex;
      filter: var(--icon-filter);
    }
  }
}
