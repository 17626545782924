@import "../../../../styles/tools";
@import "../../../../styles/mediaqueries";
@import "../../../../styles/mixins";

.mobileMenu {
  max-width: 100vw;
  overflow: hidden;
  font-size: pxToRem(18);
  @include soft-float;
  background-color: var(--white);
  position: relative;
  z-index: var(--z-index-header);

  &.isOpen {
    box-shadow: none;

    .mobileInner {
      display: flex;
    }
  }

  &.tobiiGroup {
    background-color: var(--signature);
    .mobileMenuHeader {
      .logoCorporate span {
        color: var(--white);
      }
    }

    .mobileInner {
      background-color: var(--white);
    }
  }

  @include not-touchscreen {
    @include desktop {
      display: none;
    }
  }
  @media (min-width: 1025px) and (pointer: coarse) {
    display: block;
  }
}

// Header
.mobileMenuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  max-width: var(--width-x-wide);
  width: calc(100vw - var(--gutter, var(--space-6)));
  margin: 0 auto;

  .navBtnContainer {
    display: flex;
    gap: var(--space-2);

    .langAndSearch {
      position: relative;
      top: pxToRem(2);
    }
  }

  .mobileNavButton {
    all: unset;
    display: flex;
    cursor: pointer;
  }

  .logo {
    width: pxToRem(64);

    svg {
      width: pxToRem(64);
    }
  }

  .logoCorporate {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: pxToRem(4);
    text-decoration: none;

    span {
      text-transform: uppercase;
      font-size: pxToRem(10);
      text-decoration: none;
      font-family: var(--font-heading-medium);
    }
  }
}

// Inner
.mobileInner {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - var(--header-height));
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--tobii-frost-blue);
  border-top: 2px solid var(--tobii-frost-blue-30b);
  padding-bottom: var(--space-6);
  -webkit-overflow-scrolling: touch;
}

// Navigation
.mobileNav {
  display: flex;
}

// Subnav title
.subNavTitle {
  width: 100%;
  font-family: var(--font-heading-medium);

  &.level-0 {
    .subNavTitleContent {
      padding: 0 0 0 var(--space-3);
      margin: 0;
    }
  }

  &.level-1 {
    font-size: pxToRem(16);

    .subNavTitleContent {
      padding: 0;
      margin: 0 0 0 var(--space-4);
    }
  }

  .subNavTitleContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0 -1px 0 var(--tobii-frost-blue-30b);
    grid-gap: var(--space-2);
  }

  a,
  span {
    color: var(--tobii-link-color);
  }

  a {
    font-family: var(--font-heading-medium);
    color: var(--signature);
    text-decoration: none;
  }

  button {
    border: unset;
    padding: var(--space-2) var(--space-3) var(--space-2) var(--space-2);
    background-color: transparent;
  }
}

.navList {
  min-width: 100vw;
  margin-top: var(--space-2);
  transition: color 0.25s ease;
  background-color: var(--tobii-frost-blue);
  margin: 0;
  color: var(--foreground-1);

  &.level-0 {
    background-color: var(--tobii-frost-blue);
    font-family: var(--font-heading-medium);
  }

  &.level-1 {
    transform: translateX(-100%) !important;
  }

  &.level-2 {
    transform: translateX(-200%) !important;
  }

  &.level-3 {
    transform: translateX(-300%) !important;
  }

  &.level-4 {
    transform: translateX(-400%) !important;
  }
}

.navItem {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 0 var(--space-3);
  grid-gap: var(--space-2);

  a,
  div {
    all: unset;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    padding: var(--space-2) 0;
    grid-gap: var(--space-1);
    flex: 1;
  }
  svg {
    path {
      color: var(--foreground-1);
    }
  }
}

.navItemItem {
  @extend .navItem;
  > a,
  > div,
  > button {
    padding: var(--space-2) 0 var(--space-1) var(--space-2);
    font-size: pxToRem(16);

    > span {
      box-shadow: 0 2px 0 var(--tobii-frost-blue-30b);
    }
  }
}

.navItemItemItem {
  @extend .navItem;
  > a,
  > div,
  > button {
    padding: var(--space-1) 0;
    font-size: pxToRem(14);
  }
}

.tabItem {
  padding-left: var(--space-4);
  > div {
    font-family: var(--font-heading-medium);
    font-size: pxToRem(16);
  }
}

.hasSubItems,
.hasDirectLink {
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  padding-bottom: var(--space-1);

  > a,
  > div {
    font-family: var(--font-heading-medium);
  }
}

.viewAllButton {
  @extend .navItem;
  background-color: transparent;
  color: var(--foreground-1);
  font-weight: var(--font-weight-medium);
  border: none;
  cursor: pointer;
  text-decoration: none;
  grid-gap: 0;

  .icon {
    margin-left: pxToRem(4);
  }
}

.navItemButton {
  all: unset;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  padding: var(--space-2) var(--space-3);
}

// Footer
.mobileFooterItems {
  display: flex;
  list-style-type: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--signature);
  border-top: 1px solid var(--tobii-frost-blue-30b);
  font-weight: var(--font-weight-medium);
  padding: var(--space-2) 0;
  margin-top: var(--space-2);

  .mobileFooterItem {
    all: unset;
    cursor: pointer;
    background-color: transparent;
    background-origin: 0;
    padding: var(--space-2);
    color: var(--signature);
    font-weight: var(--font-weight-medium);
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: var(--space-1);
    padding: var(--space-2) var(--space-3);
  }
}

// Languages
.mobileMenuLanguages {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-top: 2px solid var(--white-smoke);
  font-size: pxToRem(12);

  li {
    display: flex;
    justify-content: center;
    text-align: center;
    flex: 1;
    border-right: 2px solid var(--white-smoke);

    &:last-child {
      border-right: 0;
    }
  }

  .mobileMenuLangItem {
    display: flex;
    padding: var(--space-2);
    justify-content: center;
    align-items: center;
    grid-gap: var(--space-1);
    width: 100%;
    text-decoration: none;
    padding: var(--space-2);

    span {
      color: var(--signature);
      font-weight: var(--font-weight-medium);
    }
  }
}

// CTA
.subNavCTA {
  display: none;
}

// Search
// This component is not in use
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--space-3);
}

.searchLabel {
  width: 100%;
}

.searchInput {
  margin-top: 0;
}

:export {
  iconcolor: var(--signature);
  tobiigroupiconcolor: var(--white);
}
