@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.editorial {
  // Spacing
  &.onlyTop {
    padding-bottom: 0;
  }

  &.onlyBottom {
    padding-top: 0;
  }

  &.both {
    padding-top: var(--space-12);
    padding-bottom: var(--space-12);
  }

  &.none {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.editorial,
.editorial > section {
  display: flex;
  flex-direction: column;
  gap: pxToRem(40);
}

.withPadding {
  @include section-padding;
}
