@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.overlay {
  position: fixed;
  inset: 0;
  background-color: var(--black-transparent-25);
  @include backdrop-blur();
  z-index: var(--z-index-modal);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100vw - var(--space-6));
  min-width: pxToRem(320);
  max-height: calc(100vh - var(--space-6));
  overflow-y: auto;
  background-color: var(--background-1);
  transform: translate(-50%, -50%);
  outline: transparent;
  @include soft-float;
  padding: var(--space-3);
  z-index: var(--z-index-modal);
  @include radius("lg");

  @include laptop {
    max-width: clamp(pxToRem(327), calc(50vw + pxToRem(160)), pxToRem(560));
    padding: var(--space-6);
  }
}
