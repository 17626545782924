@import "../../../styles/tools";
@import "../../../styles/mediaqueries";
@import "../../../styles/mixins";

.alert {
  position: relative;
  padding: var(--space-2);
  margin-bottom: var(--space-3);
  @include radius("md");
  display: flex;
  flex-direction: row;
  grid-gap: var(--space-2);

  &.dismissible {
    padding-right: var(--space-5);
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  grid-gap: var(--space-1) / 2;
  flex: 1;
}

.title {
  display: block;
  font-weight: var(--font-weight-bold);
  font-size: pxToRem(14);
}

.message {
  font-size: pxToRem(14);
}

.icon {
  display: none;

  @include tablet {
    display: flex;
  }
}

.closeButton {
  all: unset;
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--space-2);
  appearance: none;
  color: inherit;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.15s ease;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

// Alert variants
.success {
  color: rgb(10, 54, 34);
  background-color: rgb(209, 231, 221);

  .icon path {
    fill: transparent;
    stroke: #0a3622;
  }
}

.warning {
  color: rgb(102, 77, 3);
  background-color: rgb(255, 243, 205);

  .icon path {
    fill: transparent;
    stroke: #664d03;
  }
}

.danger {
  color: rgb(88, 21, 28);
  background-color: rgb(248, 215, 218);

  .icon path {
    fill: transparent;
    stroke: #58151c;
  }
}

.info {
  color: rgb(5, 81, 96);
  background-color: rgb(207, 244, 252);

  .icon path {
    fill: transparent;
    stroke: #055160;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  animation: fadeOut 0.3s ease forwards;
}

:export {
  iconcolor: var(--black);
}
