@import "../../styles/tools";
@import "../../styles/mediaqueries";

:export {
  iconcolor: var(--cta);
}

.langList {
  padding: var(--space-1) 0;
}

.description {
  font-size: pxToRem(14);
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--background-2);

  &.Deutsch,
  &.Francés,
  &.Español {
    display: none;
  }

  a {
    all: unset;
    cursor: pointer;
    width: 100%;
    padding: var(--space-2) 0;
    font-size: pXtoRem(16);
  }

  a:active,
  a:focus,
  a:hover,
  li:active,
  li:focus {
    font-weight: var(--font-weight-bold);
  }

  .leftSide {
    display: flex;

    svg {
      padding-right: var(--space-2);
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.selected {
  font-weight: var(--font-weight-bold);
  line-height: pxToRem(28.8);
  color: var(--cta);
}
