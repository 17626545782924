@import "../../../styles/mediaqueries";
@import "../../../styles/tools";
@import "../../../styles/mixins";

.sharegraphContainer {
  margin-left: 0;

  :global {
    /*
    .mf-stock-table {
      .mf-table {
        width: 100%;
        border-collapse: collapse;
        align-self: start;

        th,
        td {
          padding: var(--space-1) var(--space-2);
        }

        thead {
          th,
          td {
            color: var(--signature);
            border-bottom: 3px solid var(--signature);
          }
        }

        tbody {
          tr {
            td {
              transition: all 0.25s;
              font-size: pxToRem(16);
              border-bottom: 1px solid rgba(14, 16, 62, 0.2);
            }

            &:first-child {
              td {
                font-weight: var(--font-weight-bold);
                background-color: var(--signature);
                color: var(--white);
              }
            }

            &:hover {
              td {
                background-color: rgba(14, 16, 62, 0.05);
              }
            }
          }
        }

        .mf-sum-row,
        .mf-other-row,
        .mf-total-row {
          strong {
            color: var(--signature);
          }
        }
      }
    }
      */

    .mf-stock-chart {
      .rangeSelector {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: var(--space-1);

        > span {
          width: var(--space-3) !important;
        }
      }

      .rangeSelectorButton {
        all: unset;
        @include tag;
        text-decoration: none;
        color: var(--tobii-link-color);
        font-weight: var(--font-weight-medium);
        cursor: pointer;
        box-shadow: none !important;
        background-color: var(--stroke-1) !important;
        font-size: pxToRem(10) !important;

        &:hover,
        &.checked {
          background-color: var(--tobii-link-color) !important;
          color: var(--white) !important;
          box-shadow: none !important;
        }
      }

      .showBtn {
        @include tag;
        text-decoration: none;
        color: var(--tobii-link-color);
        font-weight: var(--font-weight-medium);
        cursor: pointer;
        box-shadow: none !important;
        background-color: var(--stroke-1) !important;
        width: var(--space-6) !important;

        .fas {
        }
        .buttonText {
        }

        &:hover,
        &.checked {
          background-color: var(--tobii-link-color) !important;
          color: var(--white) !important;
          fill: var(--white) !important;
          box-shadow: none !important;
        }
      }

      .selector.dropdown {
        .dropdownTrigger {
          @include tag;
          text-decoration: none;
          color: var(--tobii-link-color);
          font-weight: var(--font-weight-medium);
          cursor: pointer;
          box-shadow: none !important;
          background-color: var(--stroke-1) !important;
        }

        .dropdownContent {
          .option {
            font-size: pxToRem(12) !important;
            font-weight: var(--font-weight-medium);

            &:hover {
              font-weight: var(--font-weight-medium);
              background-color: var(--background-2) !important;
            }

            &.checked {
              font-weight: var(--font-weight-medium);
              background-color: var(--tobii-link-color) !important;
              color: var(--white) !important;
            }
          }
        }
      }
    }

    .mf-stock-calculator {
      display: flex;
      flex-direction: column;
      gap: var(--space-4);

      .mf_input-wrapper {
        display: inline-flex;
        align-items: center;
        gap: var(--space-1);
        margin-right: var(--space-1);

        label {
          font-weight: var(--font-weight-bold);
          cursor: pointer;
        }
      }
      input {
        height: pxToRem(40);
        padding-left: pxToRem(16);
        border: 2px solid var(--stroke-1);
        border-radius: pxToRem(4);
        background: var(--background-3);
        font-family: var(--font-body);
        font-size: pxToRem(16);
        line-height: pxToRem(28.8);
        font-weight: var(--font-weight-medium);

        &::placeholder {
          font-weight: var(--font-weight-normal);
          color: var(--foreground-3);
        }
      }

      button {
        --fs: #{pxToRem(12)};
        --color: var(--black);
        --shadow-expansion: 0 0 0 0.625rem;
        --shadow-expansion-cta: 0 0 0 1.25rem;
        --disabled-color: rgb(56 61 86 / 40%);
        --disabled-bg: rgb(0 0 0 / 10%);
        --disabled-inverted-color: rgb(255 255 255 / 30%);
        --disabled-inverted-bg: rgb(255 255 255 / 25%);

        all: unset;
        width: fit-content;
        gap: var(--space-2);
        display: inline-flex;
        align-items: center;
        justify-self: baseline;
        color: var(--color);
        font-family: var(--font-heading-medium);
        font-size: var(--fs);
        font-weight: var(--font-weight-normal);
        text-transform: uppercase;
        position: relative;
        appearance: none;
        background: transparent;
        padding: 0.65em 1.5em;
        border: 0;
        border-radius: 50px;
        cursor: pointer;
        will-change: box-shadow;
        transition: all 0.25s;
        background-color: var(--tobii-link-color);
        --color: var(--white);

        &:focus::before {
          box-shadow: var(--tobii-link-color) 0 0 0 2px;
        }

        background-image: radial-gradient(#0680cd 0%, rgb(0 91 148 / 0%) 80%);
        background-position: 50% -30px;
        background-size: 130% 130%;
        background-repeat: no-repeat;
        box-shadow: none;

        &:hover {
          transition: all 0.25s;
          border-radius: 50px;
          background-position: 50% -35px;
        }

        &:active {
          box-shadow: none;
          transition: all 0s;
          border-radius: 50px;
          background-position: 50% -15px;
          transform: translateY(1px);
        }

        &::after {
          color: var(--tobii-link-color);
        }

        &:focus:not(:active)::after {
          --shadow-expansion: var(--shadow-expansion-cta);

          animation: ray 2s;
          animation-timing-function: cubic-bezier(0, 0.5, 0, 1);
        }

        &[disabled] {
          background-color: var(--disabled-bg);
          background-image: none;
          --color: var(--disabled-color);
          box-shadow: none;
        }
      }
    }

    .mf_date-range,
    .mf_number-of-shares {
      h2 {
        font-size: pxToRem(24);
        font-weight: var(--font-weight-medium);
      }

      p {
        font-size: pxToRem(16);
        margin-top: 0;
        margin-bottom: var(--space-2);
      }
    }

    .result-table {
      table {
        width: 100%;
        border-collapse: collapse;
        align-self: start;
        min-width: pxToRem(720);

        th,
        td {
          font-size: pxToRem(14);
          padding: var(--space-2);
          text-align: left;
        }

        thead {
          th,
          td {
            font-size: pxToRem(16);
            color: var(--signature);
            border-bottom: 3px solid var(--signature);
          }
        }

        tbody {
          tr {
            td {
              transition: all 0.25s;
              font-size: pxToRem(14);
              border-bottom: 1px solid rgba(14, 16, 62, 0.2);

              &:first-child {
                width: pxToRem(140);
                font-weight: var(--font-weight-bold);
                color: var(--signature);
              }
            }

            &:hover {
              td {
                background-color: rgba(14, 16, 62, 0.05);
              }
            }
          }
        }
      }
    }

    .mf-stock-table {
      table {
        width: 100%;
        border-collapse: collapse;
        align-self: start;
        min-width: pxToRem(600);

        th,
        td {
          font-size: pxToRem(14);
          padding: var(--space-1);
          text-align: left;
        }

        thead {
          th,
          td {
            font-size: pxToRem(16);
            color: var(--signature);
            border-bottom: 3px solid var(--signature);
          }
        }

        tbody {
          tr {
            td {
              transition: all 0.25s;
              font-size: pxToRem(14);
              border-bottom: 1px solid rgba(14, 16, 62, 0.2);

              &:first-child {
                width: pxToRem(240);
                font-weight: var(--font-weight-bold);
                color: var(--signature);
              }

              &:last-child {
                text-align: right;
              }
            }

            &:hover {
              td {
                background-color: rgba(14, 16, 62, 0.05);
              }
            }

            &:first-child {
              td,
              th {
                font-weight: var(--font-weight-bold);
                border-bottom: 3px solid var(--signature);
                color: var(--signature);
              }
            }
          }
        }
      }
    }
  }
}
