@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.hero {
  position: relative;
}

// Small Hero
.isSmall {
  background-color: var(--white);
  min-height: pxToRem(320);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  padding: var(--space-6) 0;

  @include desktop {
    padding: var(--space-20) 0 var(--space-16);
  }

  .visual,
  .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .content {
    grid-gap: var(--space-2);

    h1,
    .description,
    .label {
      color: var(--black);
    }
  }

  &.inverted {
    .content {
      h1,
      .description,
      .label {
        color: var(--white);
      }
    }
  }
}

// Regular Hero
.isRegular {
  background-image: linear-gradient(0deg, transparent, var(--white));

  .inner {
    display: grid;
    gap: var(--space-6);
    grid-template-columns: 1fr;
    padding-top: var(--space-4);
    padding-bottom: var(--space-8);

    @include tablet {
      gap: var(--space-4);
      padding-top: var(--space-6);
      padding-bottom: var(--space-10);
    }

    @include desktop {
      grid-template-columns: 2fr 3fr;
      padding-top: var(--space-20);
      padding-bottom: var(--space-16);
    }
  }

  .content {
    grid-gap: var(--space-2);

    @include desktop {
      grid-gap: var(--space-3);
    }

    .label {
      font-size: pxToRem(12);
    }
  }

  .visual {
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: var(--white);
    @include cool-glow(1);
    @include radius("lg");
    overflow: hidden;

    > div {
      width: 100%;
      height: 100%;
    }

    .background {
      overflow: hidden;
      @include radius("lg");
    }
  }

  .imageContainer {
    position: relative;
  }

  h1 {
    color: var(--black) !important;
  }
}

.inner {
  position: relative;
  z-index: 1;
}

.visual {
  position: relative;
}

.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;

  > * {
    margin: 0;
  }

  .label {
    font-style: normal;
    font-weight: var(--font-weight-medium);
    font-size: pxToRem(10);
    line-height: pxToRem(14);
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  h1 {
    max-width: pxToRem(960);
    line-height: 1.1;
    font-size: pxToRem(32);

    @include tablet {
      font-size: pxToRem(40);
    }
  }

  .description {
    max-width: pxToRem(720);
    font-family: var(--font-body);
    font-weight: var(--font-weight-medium);

    @include tablet {
      font-size: pxToRem(20);
    }
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--space-2);

  &:empty {
    display: none;
  }
}

.inverted {
  h1 {
    color: var(--black);
  }

  .label,
  .description {
    color: var(--foreground-2);
  }
}

// Dividers
.bottomImage,
.topImage {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
}

.topImage {
  top: 0;
}

.bottomImage {
  bottom: 0;
  display: none;
}
