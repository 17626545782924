@import "../../../styles/tools";
@import "../../../styles/mediaqueries";
@import "../../../styles/mixins";

.searchPage {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-4);
}

.filter,
.input,
.body {
}

.filter {
}

.input {
}

.body {
}

.toolbar {
  display: grid;
  background-color: var(--white);
  @include radius("xl");
  padding: var(--space-2);
  @include soft-float;
  grid-template-columns: 1fr;
}
