@import "../../../styles/mediaqueries";
@import "../../../styles/tools";
@import "../../../styles/mixins";

.subscription {
  margin-left: 0;
  padding-bottom: var(--space-1);

  :global {
    .mfn-subscribe {
    }

    .mfn-block {
      margin: 0;

      p {
        margin: 0;
      }
    }

    .mfn-info {
      &.mfn-info-main {
        font-size: pxToRem(20);
        margin-bottom: var(--space-1);
      }

      &.mfn-info-options,
      &.mfn-info-languages {
        font-size: pxToRem(16);
        margin-bottom: var(--space-1);
        font-weight: var(--font-weight-bold);
      }

      &.mfn-info-languages {
      }
    }

    .mtn-info-main {
    }

    .mfn-options,
    .mfn-languages {
      margin-bottom: var(--space-4);
      .mfn-list {
        display: flex;
        flex-direction: column;
        gap: var(--space-2);
        margin: 0;
        border: none;

        @include laptop {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: var(--space-2);
        }
      }
    }

    .mfn-info-languages {
    }

    .mfn-fields {
      margin-bottom: var(--space-3);
    }

    .mfn-field-input {
      width: 100%;
      height: pxToRem(52);
      padding-left: pxToRem(16);
      border: 2px solid var(--stroke-1);
      border-radius: pxToRem(4);
      background: var(--background-3);
      font-family: var(--font-body);
      font-size: pxToRem(16);
      line-height: pxToRem(28.8);
      font-weight: var(--font-weight-medium);

      &::placeholder {
        font-weight: var(--font-weight-normal);
        color: var(--foreground-3);
      }
    }

    .mfn-policy {
      display: flex;
      flex-direction: column;
      gap: var(--space-1);
      margin-bottom: var(--space-4);

      .mfn-policy-text {
        font-size: pxToRem(16);

        a {
          color: var(--tobii-link-color);
        }
      }

      .mfn-policy-approve {
        .mfn-sub-approve {
        }
        label {
        }
      }
    }

    // Submit
    .mfn-sub-submit {
      --small-text: #{pxToRem(10)};
      --fs: #{pxToRem(13)};
      --color: var(--black);
      --shadow-expansion: 0 0 0 0.625rem;
      --shadow-expansion-cta: 0 0 0 1.25rem;
      --disabled-color: rgb(56 61 86 / 40%);
      --disabled-bg: rgb(0 0 0 / 10%);
      --disabled-inverted-color: rgb(255 255 255 / 30%);
      --disabled-inverted-bg: rgb(255 255 255 / 25%);

      all: unset;
      width: fit-content;
      gap: var(--space-2);
      display: flex;
      align-items: center;
      justify-self: baseline;
      color: var(--color);
      font-family: var(--font-heading-medium);
      font-size: var(--fs);
      font-weight: var(--font-weight-normal);
      text-transform: uppercase;
      position: relative;
      appearance: none;
      background: transparent;
      padding: 1em 1.5em;
      border: 0;
      border-radius: 50px;
      cursor: pointer;
      will-change: box-shadow;
      transition: all 0.25s;
      background-color: var(--tobii-link-color);
      --color: var(--white);

      &:focus::before {
        box-shadow: var(--tobii-link-color) 0 0 0 2px;
      }

      background-image: radial-gradient(#0680cd 0%, rgb(0 91 148 / 0%) 80%);
      background-position: 50% -30px;
      background-size: 130% 130%;
      background-repeat: no-repeat;
      box-shadow: none;

      &:hover {
        transition: all 0.25s;
        border-radius: 50px;
        background-position: 50% -35px;
      }

      &:active {
        box-shadow: none;
        transition: all 0s;
        border-radius: 50px;
        background-position: 50% -15px;
        transform: translateY(1px);
      }

      &::after {
        color: var(--tobii-link-color);
      }

      &:focus:not(:active)::after {
        --shadow-expansion: var(--shadow-expansion-cta);

        animation: ray 2s;
        animation-timing-function: cubic-bezier(0, 0.5, 0, 1);
      }

      &[disabled] {
        background-color: var(--disabled-bg);
        background-image: none;
        --color: var(--disabled-color);
        box-shadow: none;
      }
    }

    // Status
    .mfn-status {
    }

    // Checkboxes
    .mfn-checkbox-row,
    .mfn-policy-approve {
      display: grid;
      grid-template-columns: var(--space-2) auto;
      grid-template-areas:
        "control"
        "label";
      column-gap: var(--space-2);
      align-items: center;
      position: relative;
      cursor: pointer;

      label {
        cursor: pointer;
      }
    }

    .mfn-sub-lang,
    .mfn-sub-option,
    .mfn-sub-approve {
      position: relative;
      all: unset;
      appearance: none;
      display: flex;
      align-items: center;
      grid-area: control;
      place-content: center;
      box-sizing: border-box;
      width: pxToRem(20);
      height: pxToRem(20);
      margin: 0;
      background-color: var(--white);
      border: 2px solid var(--black);
      border-radius: pxToRem(4);
      font: inherit;
      cursor: pointer;

      & + label {
        text-transform: none;
        font-size: pxToRem(14);
        line-height: pxToRem(20);
        font-weight: var(--font-weight-medium);
      }
    }

    // Checkboxes :checked
    .mfn-checkbox-row-checked .mfn-sub-option,
    .mfn-checkbox-row-checked .mfn-sub-lang,
    .mfn-sub-approve:checked {
      position: relative;
      border: none;
      background-image: radial-gradient(
        50% 75.3% at 50% 0%,
        #0680cd 0%,
        #005b94 100%
      );

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        background-size: 100%;
        width: pxToRem(12);
        height: pxToRem(12);
        margin-top: pxToRem(2);
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 18 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.9752 0.664484C17.6856 1.37493 17.6856 2.52678 16.9752 3.23722L6.51854 13.6939L1.1654 8.34071C0.454958 7.63027 0.454958 6.47842 1.1654 5.76797C1.87584 5.05753 3.0277 5.05753 3.73814 5.76797L6.51854 8.54838L14.4024 0.664484C15.1129 -0.0459576 16.2647 -0.0459577 16.9752 0.664484Z' fill='white'/%3E%3C/svg%3E");
      }

      & + label {
        font-weight: var(--font-weight-medium);
      }
    }
  }
}
