@import "../../styles/tools";
@import "../../styles/mixins";

.tags {
  background-color: var(--white);
  @include radius("md");
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: var(--space-2);
  padding: var(--space-3);
  @include cool-glow(0.5);
}

.tagList {
  &:not(:last-child) {
    padding-bottom: var(--space-2);
    border-bottom: 1px solid var(--tobii-frost-blue-20b);
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-1);
    padding-top: var(--space-1);
  }
}

.tag {
  @include tag;
}

.gray {
  @include tag("gray");
}
