@import "../../../styles/tools.scss";
@import "../../../styles/mediaqueries";
@import "../../../styles/mixins";

.carouselImage {
  position: relative;
  width: 100%;
  height: 100%;
}

.sliderWrapper {
  width: 100%;
  padding-top: var(--space-3);
}

// Effect
.coverflow {
  .slideItem {
    width: pxToRem(360);

    @include laptop {
      width: pxToRem(400);
    }

    @include xl {
      width: pxToRem(450);
    }
  }

  .xxWide {
    .slideItem {
      @include xxl {
        width: pxToRem(520);
      }
    }
  }

  .fluid {
    .slideItem {
      @include xxl {
        width: pxToRem(600);
      }
    }
  }
}

.item {
  margin: var(--space-2) 0 var(--space-4) 0;
  position: relative;
  overflow: hidden;
  @include radius("lg");
  @include soft-float;

  .imageContainer {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    @include radius-custom("lg", "lg", "none", "none");

    img {
      transition: 0.3s ease-in-out;
    }
  }

  &.white {
    .overlay {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.75) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  &.gray {
    .overlay {
      background: linear-gradient(
        0deg,
        rgba(232, 232, 232, 1) 0%,
        rgba(232, 232, 232, 0.75) 50%,
        rgba(232, 232, 232, 0) 100%
      );
    }
  }

  &.frostBlue {
    .overlay {
      background: linear-gradient(
        0deg,
        rgba(242, 248, 255, 1) 0%,
        rgba(242, 248, 255, 0.75) 50%,
        rgba(242, 248, 255, 0) 100%
      );
    }
  }

  &.signature {
    .overlay {
      background: linear-gradient(
        0deg,
        rgba(14, 16, 62, 1) 0%,
        rgba(14, 16, 62, 0.75) 50%,
        rgba(14, 16, 62, 0) 100%
      );
    }
  }

  &.black {
    .overlay {
      background: linear-gradient(
        0deg,
        rgba(0, 23, 47, 1) 0%,
        rgba(0, 23, 47, 0.75) 50%,
        rgba(0, 23, 47, 0) 100%
      );
    }
  }

  &.blue {
    .overlay {
      background: linear-gradient(
        0deg,
        rgba(0, 113, 235, 1) 0%,
        rgba(0, 113, 235, 0.75) 50%,
        rgba(0, 113, 235, 0) 100%
      );
    }
  }

  &.peach {
    .overlay {
      background: linear-gradient(
        0deg,
        rgba(253, 111, 96, 1) 0%,
        rgba(253, 111, 96, 0.75) 50%,
        rgba(253, 111, 96, 0) 100%
      );
    }
  }

  &.violet {
    .overlay {
      background: linear-gradient(
        0deg,
        rgba(128, 24, 216, 1) 0%,
        rgba(128, 24, 216, 0.75) 50%,
        rgba(128, 24, 216, 0) 100%
      );
    }
  }

  &.overlayActive {
    .inner {
      @include xxl {
        top: calc(var(--space-9) * -1);
        margin-bottom: calc(var(--space-9) * -1);
      }
    }

    .overlay {
      display: none;
      @include xxl {
        display: block;
        position: relative;
        height: var(--space-6);
        bottom: -1px;
      }
    }

    .imageContainer {
      @include xxl {
        @include radius-custom("lg", "lg", "none", "none");
      }
    }
  }

  .inner {
    @include radius-custom("none", "none", "lg", "lg");
    overflow: hidden;
    position: relative;

    &.shapeOverlay {
      .content {
        @include xxl {
          padding-top: var(--space-2);
        }
      }

      .shape {
        display: none;
        @include xxl {
          display: block;
          position: relative;
          width: 100%;
          bottom: -8px;
        }
      }
    }

    .content {
      padding: var(--space-3);
      min-height: pxToRem(160);
      transition: 0.3s ease-in-out;
      position: relative;
    }
  }
}

// Navigation & pagination
.navigation {
  padding-top: var(--space-1);
  display: flex;
  gap: var(--space-2);
  justify-content: center;
  align-items: center;
  text-align: center;
}
//Stacking
.stackItem {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.3s ease, transform 0.5s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  .stackContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .imageContainer {
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;
  }

  .textContainer {
    margin-top: 20px;
    text-align: center;
  }
}
