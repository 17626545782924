/* stylelint-disable */
@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

.ctaForm {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.topImage,
.bottomImage {
  position: absolute;
  left: 0;
  right: 0;
}
.topImage {
  top: 0;
}

.bottomImage {
  bottom: -6px;
}

// Inner wrapper
.wrapper {
  position: relative;
  display: grid;
  grid-gap: pxToRem(24);
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: center;
  grid-auto-flow: row;
  z-index: 1;
  height: 100%;

  @include mobile {
    width: 100%;
  }

  @include desktop {
    grid-gap: pxToRem(40);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
  }
}

// Content
.content {
  display: grid;
  gap: var(--space-2);
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: flex-start;
  grid-auto-flow: row;

  @include mobile {
    padding: 0 var(--space-3);
  }

  .intro,
  .copy {
    p:first-child {
      margin-top: 0;
    }
  }

  .intro {
    font-size: pxToRem(20);
  }

  .imageContainer {
    margin: var(--space-1) auto 0;
    max-width: 720px;
    position: relative;
    width: 100%;
    overflow: hidden;
    @include radius("lg");

    img[src*="undefined"] {
      position: unset !important;
    }

    @include desktop {
      margin-left: initial;
      margin-right: initial;
      max-width: 100%;
    }

    &:empty {
      display: none;
    }
  }
}

// Form
.form {
  position: relative;
  width: 100%;
  max-width: 32rem;
  margin: 0 auto;
  background-color: var(--white);
  padding: var(--space-3) var(--space-3);
  @include soft-float(2);

  @include mobile {
  }

  @include tablet {
    @include radius("lg");
    padding: var(--space-4);
  }
}

.loadingContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
