@import "../../../styles/tools.scss";
@import "../../../styles/mediaqueries";
@import "../../../styles/mixins";

.carouselVideo {
  position: relative;
  width: 100%;
  height: 100%;
}

.sliderWrapper {
  width: 100%;
  padding-top: var(--space-3);
}

// Effect
.coverflow {
  .slideItem {
    width: pxToRem(360);

    @include laptop {
      width: pxToRem(400);
    }

    @include xl {
      width: pxToRem(450);
    }
  }

  .xxWide {
    .slideItem {
      @include xxl {
        width: pxToRem(520);
      }
    }
  }

  .fluid {
    .slideItem {
      @include xxl {
        width: pxToRem(600);
      }
    }
  }
}

.item {
  margin: var(--space-2) 0 var(--space-4) 0;
  position: relative;
  overflow: hidden;
  @include radius("lg");
  @include soft-float;
}

.videoContainer {
  > div > div > div {
    @include radius-custom("lg", "lg", "none", "none");
  }
}

.inner {
  @include radius-custom("none", "none", "lg", "lg");
  overflow: hidden;
  position: relative;

  .content {
    padding: var(--space-3);
    transition: 0.3s ease-in-out;
    min-height: pxToRem(160);

    h4 {
    }

    p {
      font-size: pxToRem(16);
    }

    &.inverted {
      color: var(--white);
    }
  }
}

// Navigation
.navigation {
  padding-top: var(--space-1);
  display: flex;
  gap: var(--space-2);
  justify-content: center;
  align-items: center;
  text-align: center;
}
