@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.section {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.imageContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  border: var(--border);
  overflow: hidden;
}
