@import "../../styles/tools";
@import "../../styles/mediaqueries";

.shape {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  filter: blur(20px);
  z-index: -1;
}

.outTop {
  fill: rgba(0, 113, 235, 0.05);
  //fill: rgba(172, 145, 248, 0.08);
  animation: rotate 70s linear infinite;
  transform-origin: 13px 25px;
}
.inTop {
  fill: rgba(0, 79, 165, 0.05);
  //fill: rgba(131, 168, 247, 0.08);
  animation: rotate 60s linear infinite;
  transform-origin: 13px 25px;
}
.outBottom {
  fill: rgba(166, 93, 227, 0.05);
  //fill: rgba(120, 237, 226, 0.08);
  animation: rotate 75s linear infinite;
  transform-origin: 84px 93px;
}
.inBottom {
  fill: rgba(166, 93, 227, 0.05);
  //fill: rgba(131, 168, 247, 0.08);
  animation: rotate 65s linear infinite;
  transform-origin: 84px 93px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// UX Explore colors
// 172, 145, 248 purple
// 120, 237, 226 green
// 131, 168, 247 blue
