@import "../../styles/mediaqueries";

.hideAboveXl {
  @include xl {
    display: none;
  }
}

.hideBelowXl {
  display: none;

  @include xl {
    display: var(--display, block);
  }
}

.hideAboveLaptop {
  @include laptop {
    display: none;
  }
}

.hideBelowLaptop {
  display: none;

  @include laptop {
    display: var(--display, block);
  }
}

.hideAboveDesktop {
  @include desktop {
    display: none;
  }
}

.hideBelowDesktop {
  display: none;

  @include desktop {
    display: var(--display, block);
  }
}
