@import "../../styles/tools";
@import "../../styles/mixins";

.pastEvent {
  @include tag;
  z-index: 100;
}

// Investor styling
.investor {
}
