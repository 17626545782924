@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

:export {
  iconcolor: var(--black);
}

.anchorMenu {
  --transition-timing: cubic-bezier(0.4, 0, 0.2, 1);
  --transition-duration: 0.2s;

  z-index: var(--z-index-anchor-menu);
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  height: var(--anchormenu-height);
  min-height: var(--anchormenu-height);
  margin-top: calc(pxToRem(22) * -1);

  @include desktop {
    overflow: visible;
  }

  &.sticky {
    position: fixed;
    top: pxToRem(16);
    margin-top: 0;
    transition: transform var(--transition-duration) var(--transition-timing);

    @include desktop {
      top: pxToRem(60);
    }

    .item.active {
      @include desktop {
        opacity: 1;
      }
    }
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  @include soft-float;
  @include radius("2xl");
  background-color: var(--white);
  transform: translate3d(0, 0, 0);
  will-change: transform;
  transition: background-color var(--transition-duration)
    var(--transition-timing);
  padding: calc(var(--space-1) / 2);

  @include desktop {
    width: calc(100vw - var(--gutter, var(--space-6)));
  }
}

.list {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0;
  overflow: hidden;
  height: 100%;

  @include desktop {
    gap: calc(var(--space-1) / 2);
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.item {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: var(--foreground-1);
  opacity: 0;
  transition: opacity 0.3s linear, transform 0.2s ease;
  @include radius("lg");
  height: 100%;

  @include desktop {
    opacity: 1;
    transition: unset;
  }

  .link {
    color: var(--black);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    font-size: pxToRem(12);
    font-weight: var(--font-weight-medium);
    @include radius("2xl");
    transition: all 0.25s;
    width: 100%;
    padding: 0 var(--space-1);
    height: var(--anchormenu-height);

    @include desktop {
      height: 100%;
      text-align: center;
    }
  }

  .link {
    @include desktop {
      &:hover {
        background-color: var(--tobii-frost-blue);
      }
    }
  }

  &.active {
    @include desktop-down {
      opacity: 1;
    }

    .link {
      @include desktop {
        color: var(--white);
        background-color: var(--tobii-ocean-blue-90b);

        &:hover {
          color: var(--white);
          background-color: var(--tobii-ocean-blue-90b);
        }
      }
    }
  }
}

.cta {
  @include desktop {
    padding-left: var(--space-1);
  }

  a {
    background-color: auto;
    color: var(--white);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    font-size: pxToRem(12);
    font-weight: var(--font-weight-medium);
    @include radius("2xl");
    transition: all 0.25s;
    width: 100%;
    padding: 0 var(--space-3) !important;
    height: var(--anchormenu-height);
    text-align: center;

    height: 100%;
    background-color: var(--tobii-link-color) !important;

    span {
      font-weight: var(--font-weight-normal);
    }

    &:hover {
      background-color: var(--tobii-link-color-hover) !important;
      color: var(--white);
    }
  }
}

.expandButton {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 var(--space-2);

  .icon {
    transform: rotate(90deg);
  }
}

.hideOnDesktop {
  @include desktop {
    display: none;
  }
}

.mobileMenu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: var(--white);
  width: pxToRem(200);
  background-color: var(--white);
  @include radius("md");
  @include soft-float;
  padding: var(--space-1) 0 var(--space-2);

  .header {
    display: flex;
    height: var(--anchormenu-height);
  }

  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 var(--space-3);
    font-size: pxToRem(12);
  }

  button {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    height: 100%;
    cursor: pointer;
  }

  ul {
    display: grid;
    gap: var(--space-1) / 2;
  }
}

.mobileItem {
  min-height: 0;
  opacity: 1;
  padding: 0 var(--space-1);

  .link {
    max-height: unset;
    height: auto;
    justify-content: flex-start;
    text-align: left;
    color: var(--black);
    padding: var(--space-1) var(--space-2);
    @include radius("sm");
  }

  &.active {
    transform: translateY(0);

    a {
      background-color: var(--tobii-ocean-blue-90b);
      color: var(--white);
    }
  }

  &.previousUp {
    transform: translateY(100%);
  }

  &.previousDown {
    transform: translateY(-100%);
  }
}

.spacer {
  height: var(--anchormenu-height);
  display: block;
  will-change: height;
}
