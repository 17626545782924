.withoutSpacing {
  > div {
    padding: 0;

    > div {
      width: 100%;
      max-width: none;
      margin: 0;
    }
  }
}
