@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";
@import "./components/TabsGrid.Layouts.module.scss";

.tabsGrid {
  position: relative;
}

.tabsContent {
  padding-top: var(--space-4);
  position: relative;
}

// Tabs
.tab {
  margin-top: var(--space-3);
}
.header {
  margin-top: var(--space-3);
  text-align: center;
}
// Grid
.grid {
  display: grid;

  @include desktop-down {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include desktop {
    grid-gap: var(--space-4);
  }
}

.gridItem {
  position: relative;
  text-align: center;
  @include radius("lg");
  background-color: var(--background-3);
  min-height: pxToRem(320);
  min-width: pxToRem(200);
  overflow: hidden;
  transform-origin: center center;
  transition: transform, box-shadow 0.2s ease-out;
  will-change: transform;

  &:hover {
    box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.1);
  }

  @include desktop-down {
    box-shadow: none;
    flex: 0 0 90%;
    scroll-snap-align: start;
    margin-right: var(--space-3);

    &:last-child {
      margin-right: 0;
    }
  }

  .gridItemInner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: var(--space-2) var(--space-2) 0;
    height: 100%;
    text-decoration: none;
  }
}

// Visual elements
.visualWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.imageContainer,
.videoContainer,
.iconContainer {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

.imageContainer {
  aspect-ratio: 16 / 9;

  @include desktop {
    max-height: pxToRem(140);
  }
}

.iconContainer {
  padding: var(--space-4) var(--space-2);
}

// Content
.content {
  z-index: 2;
}

.contentInner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--space-2) 0;
  gap: var(--space-1);

  @include desktop {
    padding: var(--space-2) 0 var(--space-8);
  }
}

.title {
  color: var(--black);
  font-size: pxToRem(16);
}

.description {
  margin: 0;
  font-size: pxToRem(14);
  color: var(--foreground-2);
}

// Cover variant
.gridItemCover {
  padding: 0;

  .visualWrapper,
  .imageContainer {
    position: absolute !important;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: var(--space-2);
  }

  .contentInner {
    padding-top: var(--space-4);
  }

  .imageContainer {
    aspect-ratio: initial;
    max-height: 100%;
  }
}

// Navigation
.sliderNavigation {
  padding-top: var(--space-3);
  display: flex;
  gap: var(--space-2);
  justify-content: center;
  align-items: center;
}

// Buttons
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space-1);

  @include desktop {
    position: absolute;
    bottom: var(--space-2);
  }
}

// Theme variants
.inverted {
  .title,
  .description {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  }

  .title {
    color: var(--foreground-i1);
  }

  .description {
    color: var(--white);

    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
      color: var(--white);
    }
  }
}
